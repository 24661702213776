import { NgxSliderModule } from 'ngx-slider-v2';
import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { ImageCropperModule } from 'ngx-image-cropper';
import { IConfig, NgxMaskDirective, provideEnvironmentNgxMask } from 'ngx-mask';
import { AccordionComponent } from './components/accordion.component';
import { AddressComponent } from './components/address.component';
import { AmenityMenuComponent } from './components/amenity-menu.component';
import { AvatarEditorComponent } from './components/avatar-editor/avatar-editor.component';
import { AvatarComponent } from './components/avatar.component';
import { ButtonComponent } from './components/button.component';
import { CardComponent } from './components/card.component';
import { CancellationPolicyChartComponent } from './components/charts/cancellation-policy-chart/cancellation-policy-chart.component';
import { CheckboxGroupComponent } from './components/checkbox-groups/checkbox-group.component';
import { CheckboxComponent } from './components/checkbox.component';
import { ChipComponent } from './components/chip.component';
import { DatepickerComponent } from './components/datepicker.component';
import { DialogActionsComponent } from './components/dialog/dialog-actions.component';
import { DialogContentComponent } from './components/dialog/dialog-content.component';
import { DialogHeaderComponent } from './components/dialog/dialog-header.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { MobileCloseDialogButtonComponent } from './components/dialog/mobile-close-dialog-button.component';
import { MobileSortDialogComponent } from './components/dialog/mobile-sort-dialog.component';
import { DropdownCountryComponent } from './components/dropdown-country.component';
import { DropdownComponent } from './components/dropdown.component';
import { FullCalendarYearComponent } from './components/full-calendar-year/full-calendar-year.component';
import { FullCalendarComponent } from './components/full-calendar/full-calendar.component';
import { HintComponent } from './components/hint.component';
import { IconComponent } from './components/icon/icon.component';
import { ImagesDragAndDropComponent } from './components/images-drag-and-drop/images-drag-and-drop.component';
import { IncrementStepperComponent } from './components/increment-stepper.component';
import { InputAddressComponent } from './components/input-address.component';
import { InputGuestsComponent } from './components/input-guests.component';
import { InputNumberComponent } from './components/input-number.component';
import { InputPasswordComponent } from './components/input-password.component';
import { InputTextComponent } from './components/input-text.component';
import { InputTextAreaComponent } from './components/input-textarea.component';
import { ProgressBarComponent } from './components/progress-bar.component';
import { RadioButtonGroupComponent } from './components/radio-button-group.component';
import { RadioButtonComponent } from './components/radio-button.component';
import { ScrimComponent } from './components/scrim.component';
import { SliderComponent } from './components/slider.component';
import { StarsComponent } from './components/stars.component';
import { StateIndicatorComponent } from './components/state-indicator.component';
import { SwitchComponent } from './components/switch.component';
import { TabsComponent } from './components/tab/tabs.component';
import { TabComponent } from './components/tab/tab.component';
import { TypographyComponent } from './components/typography.component';
import { WebGlSupportModule } from '@components/web-gl-support/web-gl-support.module';
import { WebGlSupportComponent } from '@components/web-gl-support/web-gl-support.component';
import { MouseHoldDirective } from './directives/mouse-hold.directive';
import { DropdownPropertyComponent } from './components/dropdown-property.component';
import { RadioButtonGroupAccountsComponent } from './components/radio-button-group-accounts.component';
import { DialogHeaderIconComponent } from './components/dialog/dialog-header-icon.component';
import { PhoneNumberInputComponent } from './components/phone-number-input/phone-number-input.component';
import { DropdownTimeComponent } from './components/dropdown-time.component';
import { MultiParagraphModule } from '@components/multi-paragraph/multi-paragraph.module';
import { MultiParagraphTextComponent } from '@components/multi-paragraph/multi-paragraph-text.component';
import { InputTaxJurisdictionComponent } from './components/input-tax-jurisdiction.component';
import { NotificationLegacyModule } from './notification-legacy.module';
import { NotificationComponent } from './components/notification.component';
import { PhoneInputObfuscatedLegacyModule } from './phone-input-obfuscated-legacy.module';
import { CardLegacyModule } from './card-legacy.module';
import { ImagesDragAndDropLegacyModule } from './images-drag-and-drop-legacy.module';
import { InputTextareaLegacyModule } from './input-textarea-legacy.module';
import { InputNumberLegacyModule } from './input-number-legacy.module';
import { DropdownCountryLegacyModule } from './dropdown-country-legacy.module';
import { InputAddressLegacyModule } from './input-address-legacy.module';
import { InputTextLegacyModule } from './input-text-legacy.module';
import { RadioButtonLegacyModule } from './radio-button-legacy.module';
import { InputTaxJurisdictionLegacyModule } from './input-tax-jurisdiction-legacy.module';
import { StarsLegacyModule } from './stars-legacy.module';
import { CancellationPolicyChartLegacyModule } from './cancellation-policy-chart-legacy.module';
import { ChipLegacyModule } from './chip-legacy.module';
import { RadioButtonGroupAccountsLegacyModule } from './radio-button-group-accounts-legacy.module';
import { FullCalendarLegacyModule } from './full-calendar-legacy.module';
import { TippyDirective } from '@ngneat/helipopper';
import initTooltip from './utils/init-tooltip';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    ButtonComponent,
    TypographyComponent,
    IconComponent,
    AvatarComponent,
    InputPasswordComponent,
    HintComponent,
    DialogComponent,
    DialogHeaderComponent,
    DialogHeaderIconComponent,
    DialogContentComponent,
    DialogActionsComponent,
    TabsComponent,
    TabComponent,
    DropdownComponent,
    DropdownPropertyComponent,
    CheckboxComponent,
    SwitchComponent,
    AccordionComponent,
    ScrimComponent,
    StateIndicatorComponent,
    AvatarEditorComponent,
    MouseHoldDirective,
    ProgressBarComponent,
    CheckboxGroupComponent,
    DatepickerComponent,
    MobileCloseDialogButtonComponent,
    IncrementStepperComponent,
    InputGuestsComponent,
    SliderComponent,
    RadioButtonGroupComponent,
    AmenityMenuComponent,
    MobileSortDialogComponent,
    DropdownTimeComponent,
  ],
  imports: [
    // angular
    CommonModule,
    // material components
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatIconModule,
    MatSliderModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatTooltipModule,
    // forms
    ReactiveFormsModule,
    FormsModule,
    // form mask
    NgxMaskDirective,
    // date picker
    MatDatepickerModule,
    // other
    A11yModule,
    RouterModule,
    ImageCropperModule,
    MatButtonModule,
    NgxSliderModule,
    MatRadioModule,
    TippyDirective,
    OverlayModule,
    MultiParagraphModule,
    WebGlSupportModule,
    NotificationLegacyModule,
    PhoneInputObfuscatedLegacyModule,
    CardLegacyModule,
    ImagesDragAndDropLegacyModule,
    InputTextareaLegacyModule,
    InputNumberLegacyModule,
    DropdownCountryLegacyModule,
    InputTextLegacyModule,
    InputAddressLegacyModule,
    RadioButtonLegacyModule,
    InputTaxJurisdictionLegacyModule,
    StarsLegacyModule,
    CancellationPolicyChartLegacyModule,
    ChipLegacyModule,
    RadioButtonGroupAccountsLegacyModule,
    FullCalendarLegacyModule,
  ],
  exports: [
    ButtonComponent,
    TypographyComponent,
    CardComponent,
    IconComponent,
    AvatarComponent,
    InputTextComponent,
    InputNumberComponent,
    InputPasswordComponent,
    InputTextAreaComponent,
    HintComponent,
    NotificationComponent,
    DialogComponent,
    StarsComponent,
    TabsComponent,
    TabComponent,
    DropdownComponent,
    DropdownCountryComponent,
    DropdownPropertyComponent,
    CheckboxComponent,
    SwitchComponent,
    AccordionComponent,
    ScrimComponent,
    StateIndicatorComponent,
    InputAddressComponent,
    ImagesDragAndDropComponent,
    AvatarEditorComponent,
    MouseHoldDirective,
    ProgressBarComponent,
    CheckboxGroupComponent,
    DatepickerComponent,
    DialogHeaderComponent,
    DialogHeaderIconComponent,
    DialogContentComponent,
    MobileCloseDialogButtonComponent,
    ChipComponent,
    IncrementStepperComponent,
    InputGuestsComponent,
    SliderComponent,
    RadioButtonComponent,
    RadioButtonGroupComponent,
    RadioButtonGroupAccountsComponent,
    AmenityMenuComponent,
    AddressComponent,
    FullCalendarComponent,
    FullCalendarYearComponent,
    DialogActionsComponent,
    MobileSortDialogComponent,
    CancellationPolicyChartComponent,
    WebGlSupportComponent,
    PhoneNumberInputComponent,
    DropdownTimeComponent,
    MultiParagraphTextComponent,
    InputTaxJurisdictionComponent,
  ],
  providers: [
    MatDatepickerModule,
    provideEnvironmentNgxMask(maskConfig),
    initTooltip(),
  ],
})
export class ComponentApiModule {}
