import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractInputComponent } from '@components/abstract/abstract-input.component';
import { DateInterval } from '@components/calendar/calendar.utils';
import {
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  addDays,
  addMonths,
  endOfMonth,
  format,
  isAfter,
  isBefore,
  isSameDay,
  startOfMonth,
} from 'date-fns';
import {
  CalendarDayClickEvent,
  CalendarDaySetting,
} from '@components/calendar/calendar-month/calendar-month.component';
import {
  CalendarSelection,
  CalendarSelectionColor,
} from '@components/calendar/calendar-month-overlay/calendar-month-overlay.component';
import { first } from 'rxjs';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@components/button/button.module';
import { CalendarModule } from '@components/calendar/calendar.module';
import { IconModule } from '@components/icon/icon.module';
import { InputModule } from '@components/input/input.module';
import { SeparatorModule } from '@components/separator/separator.module';
import { TypographyModule } from '@components/typography/typography.module';

@Component({
  selector: 'input-calendar',
  styleUrls: ['./input-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputModule,
    OverlayModule,
    CalendarModule,
    SeparatorModule,
    TypographyModule,
    ButtonModule,
    IconModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputCalendarComponent,
      multi: true,
    },
  ],
  template: `
    <div
      *ngIf="!showInFullscreen; else fullScreenCalendar"
      [formGroup]="internalControl"
      class="d-flex"
      [ngClass]="{ inline }"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
      (click)="onInputClick()"
    >
      <div
        class="d-flex flex-grow-1 common-input"
        [class.mb-16]="inline"
        [class.focused]="isCalendarOpen"
      >
        <input-text
          class="right-separator"
          formControlName="start"
          data-test="input-checkin"
          [label]="labelStart"
          readonly
        ></input-text>
        <input-text
          formControlName="end"
          data-test="input-checkout"
          [label]="labelEnd"
          readonly
        ></input-text>
      </div>
      <ng-container *ngIf="inline" [ngTemplateOutlet]="calendarInner" />
      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="isCalendarOpen"
        (overlayOutsideClick)="toggleCalendar(); $event.stopPropagation()"
      >
        <ng-container [ngTemplateOutlet]="calendarInner" />
      </ng-template>
    </div>
    <ng-template #calendarInner>
      <div
        [ngClass]="!inline ? 'input-calendar-popup' : 'input-calendar-inline'"
      >
        <ng-container *ngFor="let month of months; first as first">
          <separator
            variant="vertical"
            class="mx-16 h-100"
            *ngIf="!first"
          ></separator>
          <div class="input-calendar-month">
            <p typography variant="em2" class="input-calendar-month-name">
              {{ month | date : 'MMMM YYYY' }}
            </p>
            <div>
              <calendar-month-overlay [selections]="selections">
                <calendar-month
                  [daySettings]="daySettings"
                  [date]="month"
                  (dayClick)="onDayClick($event)"
                ></calendar-month>
              </calendar-month-overlay>
            </div>
          </div>
        </ng-container>
        <button
          button
          variant="inline"
          class="input-calendar-arrow input-calendar-arrow-left"
          (click)="onPrevClick()"
          [disabled]="!isPrevMonthEnabled()"
          title="Prev month"
        >
          <icon color="ink" variant="chevron_left"></icon>
        </button>
        <button
          button
          variant="inline"
          class="input-calendar-arrow input-calendar-arrow-right"
          (click)="onNextClick()"
          [disabled]="!isNextMonthEnabled()"
          title="Next month"
        >
          <icon color="ink" variant="chevron_right"></icon>
        </button>
      </div>
    </ng-template>
    <ng-template #fullScreenCalendar>
      <div class="input-calendar-fullscreen">
        <div class="input-calendar-fullscreen-header">
          <button
            button
            icon="arrow_left"
            class="mx-8"
            variant="text"
            iconColor="ink"
            (click)="back()"
          ></button>
          <span typography variant="body1">{{ getTitleLabel() }}</span>
        </div>
        <div class="input-calendar-fullscreen-months">
          <ng-container *ngFor="let month of months">
            <div class="input-calendar-month mt-16">
              <p typography variant="em2" class="input-calendar-month-name">
                {{ month | date : 'MMMM YYYY' }}
              </p>
              <div>
                <calendar-month-overlay [selections]="selections">
                  <calendar-month
                    [daySettings]="daySettings"
                    [date]="month"
                    (dayClick)="onDayClick($event)"
                  ></calendar-month>
                </calendar-month-overlay>
              </div>
            </div>
          </ng-container>
          <button
            button
            icon="chevron_down"
            iconColor="ink"
            variant="inline"
            class="py-16"
            (click)="onLoadNextClick()"
            title="Load next months"
          ></button>
        </div>
        <div class="input-calendar-fullscreen-footer">
          <button button (click)="search()" class="m-16 flex-grow-1">
            Search
          </button>
        </div>
      </div>
    </ng-template>
  `,
})
export class InputCalendarComponent
  extends AbstractInputComponent<DateInterval>
  implements OnInit, OnChanges
{
  @Input()
  readonly labelStart: string = 'Start';
  @Input()
  readonly labelEnd: string = 'End';
  @Input()
  readonly min: Date;
  @Input()
  readonly max: Date;
  @Input()
  readonly numberOfMonths = 2;
  @Input()
  readonly showInFullscreen = false;
  @Input()
  readonly inline = false;

  @Output()
  readonly searchEvent = new EventEmitter<void>();
  @Output()
  readonly backEvent = new EventEmitter<void>();

  internalControl: UntypedFormGroup;

  months: Date[];
  isCalendarOpen = false;
  selections: CalendarSelection[];
  daySettings: CalendarDaySetting[];

  private calendarNewlyOpened: boolean;
  private interval = new DateInterval(null, null);

  constructor(
    injector: Injector,
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly zone: NgZone,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.renderer.addClass(this.elementRef.nativeElement, 'input-calendar');
    this.initControls();
    this.initMonths();
    this.updateCalendar();
    this.value$.subscribe(value => {
      this.setInterval(value ?? DateInterval.empty());
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.min || changes.max) {
      this.daySettings = this.generateDaySettings();
    }
  }

  @HostListener('window:resize')
  onResize(): void {
    this.changeDetectorRef.detectChanges();
  }

  private initControls(): void {
    this.internalControl = new UntypedFormGroup({
      start: new UntypedFormControl(),
      end: new UntypedFormControl(),
    });
  }

  private initMonths(): void {
    const currentMonth = startOfMonth(new Date());
    this.months = [currentMonth];
    for (let i = 1; i < this.numberOfMonths; i++) {
      this.months.push(addMonths(currentMonth, i));
    }
  }

  onPrevClick(): void {
    if (!this.isPrevMonthEnabled()) {
      return;
    }
    this.addMonths(-1);
  }

  onNextClick(): void {
    if (!this.isNextMonthEnabled()) {
      return;
    }
    this.addMonths(1);
  }

  onLoadNextClick(): void {
    const lastMonth = this.months[this.months.length - 1];
    for (let i = 0; i < this.numberOfMonths; i++) {
      this.months.push(addMonths(lastMonth, i + 1));
    }
  }

  onInputClick(): void {
    !this.inline && this.openCalendar();
  }

  toggleCalendar(): void {
    this.isCalendarOpen ? this.closeCalendar() : this.openCalendar();
  }

  isPrevMonthEnabled(): boolean {
    if (!this.min) {
      return true;
    }
    return isAfter(startOfMonth(this.months[0]), this.min);
  }

  isNextMonthEnabled(): boolean {
    if (!this.max) {
      return true;
    }
    return isBefore(endOfMonth(this.months[this.months.length - 1]), this.max);
  }

  private openCalendar(): void {
    if (this.interval.start && this.interval.end) {
      this.initMonths();
      const d1 = new Date();
      const d2 = this.interval.start;
      let monthCount = (d2.getFullYear() - d1.getFullYear()) * 12;
      monthCount += d2.getMonth() - d1.getMonth();
      monthCount = monthCount <= 0 ? 0 : monthCount;
      this.addMonths(monthCount);
    }

    this.isCalendarOpen = true;
    this.calendarNewlyOpened = true;
  }

  private closeCalendar() {
    this.isCalendarOpen = false;
  }

  onDayClick(event: CalendarDayClickEvent): void {
    const date = event.day.date;
    if (isBefore(date, this.min)) {
      return;
    }
    if (isAfter(date, this.max)) {
      return;
    }
    const interval = this.interval.clone();
    if (this.calendarNewlyOpened) {
      this.calendarNewlyOpened = false;
      interval.start = date;
      interval.end = null;
    } else {
      if (!interval?.start) {
        interval.start = date;
      } else {
        if (isSameDay(date, interval.start) || isSameDay(date, interval.end)) {
          interval.start = null;
          interval.end = null;
        } else if (isAfter(date, interval.start)) {
          interval.end = date;
        } else {
          interval.start = date;
          interval.end = null;
        }
      }
    }
    this.setInterval(interval);
  }

  private setInterval(interval: DateInterval): void {
    this.interval = interval;
    this.internalControl.reset({
      start: this.formatDate(this.interval.start),
      end: this.formatDate(this.interval.end),
    });
    if (!this.interval.equals(this.value)) {
      this.value = this.interval.clone();
    }
    this.updateCalendar();
    if (this.isCalendarOpen) {
      if (this.interval.start && this.interval.end) {
        this.closeCalendar();
      }
    }
    this.zone.onStable
      .asObservable()
      .pipe(first())
      .subscribe(() => {
        this.changeDetectorRef.markForCheck();
      });
  }

  private formatDate(date: Date): string {
    if (!date) {
      return undefined;
    }
    return format(date, 'MM/dd/yyyy');
  }

  private addMonths(amount: number): void {
    this.months = this.months.map(month => addMonths(month, amount));
  }

  private updateCalendar(): void {
    this.selections = this.generateSelections(this.interval);
    this.daySettings = this.generateDaySettings();
  }

  private generateSelections(interval: DateInterval): CalendarSelection[] {
    const selections: CalendarSelection[] = [];
    if (interval.start && interval.end) {
      selections.push({
        interval: interval.clone(),
        color: CalendarSelectionColor.NEUTRAL,
      });
    }
    if (interval.start) {
      selections.push({
        interval: DateInterval.ofDate(interval.start),
        color: CalendarSelectionColor.PRIMARY,
      });
    }
    if (interval.end) {
      selections.push({
        interval: DateInterval.ofDate(interval.end),
        color: CalendarSelectionColor.PRIMARY,
      });
    }
    return selections;
  }

  private generateDaySettings(): CalendarDaySetting[] {
    const settings: CalendarDaySetting[] = [];
    if (this.min) {
      for (let i = 1; i < 100; i++) {
        settings.push({
          date: addDays(this.min, -i),
          textDecoration: 'line-through',
        });
      }
    }
    if (this.max) {
      for (let i = 1; i < 100; i++) {
        settings.push({
          date: addDays(this.max, i),
          textDecoration: 'line-through',
        });
      }
    }
    if (this.interval.start) {
      settings.push({
        date: this.interval.start,
        textColor: 'white',
      });
    }
    if (this.interval.end) {
      settings.push({
        date: this.interval.end,
        textColor: 'white',
      });
    }
    return settings;
  }

  getTitleLabel() {
    if (!this.interval.start && !this.interval.end) {
      return 'When do you want to go?';
    }
    return `${this.interval.start?.toDateString() ?? 'Any'} - ${
      this.interval.end?.toDateString() ?? 'Any'
    }`;
  }

  search() {
    this.searchEvent.emit();
  }

  back() {
    this.backEvent.emit();
  }
}
