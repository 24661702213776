import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { TypographyModule } from '@components/typography/typography.module';
import { ImagesDragAndDropComponent } from './components/images-drag-and-drop/images-drag-and-drop.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { AccordionModule } from '@components/accordion/accordion.module';
import { LinkModule } from '@components/link/link.module';
import { ButtonModule } from '@components/button/button.module';

@NgModule({
  declarations: [ImagesDragAndDropComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    TypographyModule,
    OverlayModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatTooltipModule,
    AccordionModule,
    LinkModule,
    ButtonModule,
  ],
  exports: [ImagesDragAndDropComponent],
})
export class ImagesDragAndDropLegacyModule {}
