import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignUpPrivacyPolicyComponent } from './components/signup/signup-privacy-policy/sign-up-privacy-policy.component';
import { SignUpTermsAndConditionsComponent } from './components/signup/sign-up-terms-and-conditions/sign-up-terms-and-conditions.component';
import { LoginRouteComponent } from './components/login/login-route/login-route.component';
import { LoginEmailRouteComponent } from './components/login/login-email-route/login-email-route.component';
import { SignUpRouteComponent } from './components/login/sign-up-route/sign-up-route.component';
import { SignUpEmailRouteComponent } from './components/login/sign-up-email-route/sign-up-email-route.component';
import { LoginForgottenRouteComponent } from './components/login/login-forgotten-route/login-forgotten-route.component';
import {
  LoginComponent,
  LoginLinkComponent,
} from './components/login/dialog/login.component';
import {
  SignUpComponent,
  SignUpLinkComponent,
} from './components/signup/dialog/sign-up.component';
import {
  LoginFormComponent,
  LoginFormLinkComponent,
} from './components/login/form/login-form.component';
import {
  SignInEmailComponent,
  SignUpFormComponent,
  SignUpFormLinkComponent,
} from './components/signup/form/sign-up-form.component';
import {
  SocialLoginGoogleComponent,
  SocialLoginHandlerComponent,
} from './components/login/social-login.handler.component';
import { EmailVerifyingHandlerComponent } from './components/email-verifying-handler.component';
import { AdditionFormComponent } from './components/signup/addition-form/addition-form.component';
import { ResetPasswordComponent } from './components/login/reset-password/reset-password.component';
import { WelcomeComponent } from './components/post-registration-dialogs/welcome/post-registration-dialogs/welcome/welcome.component';
import { CoreModule } from '../../core/core.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PhoneVerificationDialogComponent } from './components/phone-verification/phone-verification-dialog.component';
import { UploadPhotoComponent } from './components/post-registration-dialogs/upload-photo/upload-photo.component';
import { ResetPasswordConfirmationDialogComponent } from './components/login/reset-password/reset-password-confirmation-dialog.component';
import { LoginWithBookingHandlerComponent } from './components/login-with-booking-handler.component';
import { SuperhogVerificationComponent } from './components/post-registration-dialogs/superhog-verification/superhog-verification.component';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { Auth0PhoneVerificationDialogComponent } from './components/phone-verification/auth0-phone-verification-dialog.component';
import { DialogModule } from '@components/dialog/dialog.module';
import { ButtonModule } from '@components/button/button.module';
import { InputModule } from '@components/input/input.module';
import { AvatarModule } from '@components/avatar/avatar.module';
import { TypographyModule } from '@components/typography/typography.module';
import { CheckboxModule } from '@components/checkbox/checkbox.module';
import { PhoneInputObfuscatedLegacyModule } from '../../libs/component-lib/phone-input-obfuscated-legacy.module';
import { LinkModule } from '@components/link/link.module';
import { InputPasswordModule } from '@components/input-password/input-password.module';
import { PhoneNumberVerificationModule } from '@components/phone-number-verification/phone-number-verification.module';
import { NotificationLegacyModule } from '../../libs/component-lib/notification-legacy.module';
import { PrivacyPolicyContentComponent } from '../privacy-policy/privacy-policy-content/privacy-policy-content.component';
import { TermsAndConditionsContentComponent } from '../terms-and-conditions/terms-and-conditions-content/terms-and-conditions-content.component';
import { TextSeparatorComponent } from '@components/text-separator/text-separator.component';

@NgModule({
  declarations: [
    LoginLinkComponent,
    SignUpLinkComponent,
    SignUpFormComponent,
    SignUpFormLinkComponent,
    SignInEmailComponent,
    LoginFormComponent,
    SocialLoginHandlerComponent,
    EmailVerifyingHandlerComponent,
    SocialLoginGoogleComponent,
    AdditionFormComponent,
    SignUpPrivacyPolicyComponent,
    SignUpTermsAndConditionsComponent,
    LoginRouteComponent,
    LoginEmailRouteComponent,
    SignUpRouteComponent,
    SignUpEmailRouteComponent,
    LoginForgottenRouteComponent,
    LoginComponent,
    SignUpComponent,
    LoginFormLinkComponent,
    ResetPasswordComponent,
    WelcomeComponent,
    PhoneVerificationDialogComponent,
    Auth0PhoneVerificationDialogComponent,
    UploadPhotoComponent,
    ResetPasswordConfirmationDialogComponent,
    LoginWithBookingHandlerComponent,
    SuperhogVerificationComponent,
    PhoneInputComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    ReactiveFormsModule,
    RouterModule,
    TypographyModule,
    LinkModule,
    ButtonModule,
    InputModule,
    InputPasswordModule,
    DialogModule,
    AvatarModule,
    CheckboxModule,
    PhoneNumberVerificationModule,
    PhoneInputObfuscatedLegacyModule,
    NotificationLegacyModule,
    PrivacyPolicyContentComponent,
    TermsAndConditionsContentComponent,
    TextSeparatorComponent,
  ],
  exports: [
    PhoneVerificationDialogComponent,
    Auth0PhoneVerificationDialogComponent,
  ],
})
export class AuthModule {}
