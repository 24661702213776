import { Component, Inject, OnInit } from '@angular/core';
import { AbstractComponent } from '../../../../../core/components/abstract/abstract.component';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { BreakpointService } from '../../../../../core/services/breakpoint.service';

@Component({
  selector: 'app-signup-privacy-policy',
  templateUrl: './sign-up-privacy-policy.component.html',
})
export class SignUpPrivacyPolicyComponent
  extends AbstractComponent
  implements OnInit
{
  /** Large screen flag */
  ls: boolean;

  constructor(
    private readonly dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA)
    readonly data: { agreeButtonText: string },
    private readonly breakpointService: BreakpointService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.breakpointService.largeScreen$
      .pipe(this.untilDestroyed())
      .subscribe(isLargeScreen => {
        this.ls = isLargeScreen;
      });
  }

  agree() {
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close(false);
  }
}
