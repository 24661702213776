import { AbstractComponent } from '../../../../core/components/abstract/abstract.component';
import { Component, Input } from '@angular/core';
import { Booking } from '../../../../shared/models/booking';
import { addDays } from 'date-fns';

@Component({
  selector: 'app-booking-reward-card',
  template: `
    <ng-container *toggle="'rewardPointEarning'">
      <div class="reward-card">
        <icon variant="reward_point" class="d-block mr-8"></icon>
        <div>
          <div *ngIf="booking?.clientRewardAmount">
            <ng-container *ngIf="isBeforeCheckIn(); else alreadyEarned">
              You will earn
              <strong data-test="reward"
                >{{ booking.clientRewardAmount | number }} reward points</strong
              >
              for this booking.
            </ng-container>
            <ng-template #alreadyEarned>
              You earned
              <strong
                >{{ booking.clientRewardAmount | number }} reward points</strong
              >
              for this booking.
            </ng-template>
          </div>
          <div *ngIf="showHints || !booking?.clientRewardAmount">
            Get more reward points and enjoy exclusive benefits.
            <a
              link
              variant="link1"
              href="https://help.vacaymyway.com/article/50-what-are-vacayyourway-rewards"
              >Learn More</a
            >
          </div>
        </div>
      </div>
    </ng-container>
  `,
  styleUrls: ['booking-details.component.scss'],
})
export class BookingRewardCardComponent extends AbstractComponent {
  @Input()
  booking?: Booking;

  @Input()
  showHints = true;

  isBeforeCheckIn() {
    // End of check in day. Should be specific time of check in in property timezone, but currently is midnight UTC (and we want end of that day)
    return addDays(this.booking.interval.checkIn, 1) > new Date();
  }
}
