import { Component, Input } from '@angular/core';
import { Conversation } from '../../../models/conversation.model';
import { BreakpointService } from '../../../../../core/services/breakpoint.service';

@Component({
  selector: 'app-chat-conversation-content',
  templateUrl: 'chat-conversation-content.component.html',
  styleUrls: ['chat-conversation-content.component.scss'],
})
export class ChatConversationContentComponent {
  @Input()
  conversation: Conversation;

  largeScreen$ = this.bs.largeScreen$;

  @Input()
  isContextOpen = false;

  constructor(private readonly bs: BreakpointService) {}
}
