<div class="message" [class.message-by-current-user]="message.sender.isCurrentUser">
  <div class="message-content">
    <avatar class="message-avatar" [src]="message.sender.avatarUrl" [hoverDisabled]="true">
      {{message.sender | userInitials}}
    </avatar>
    <div class="message-text">
      <ng-container *ngFor="let line of getLines(text || message.text)">
        <p typography variant="body1" color="inherit" *ngIf="line; else emptyLine">{{line}}</p>
        <ng-template #emptyLine>
          <p typography variant="body1" color="inherit">&nbsp;</p>
        </ng-template>
      </ng-container>
    </div>
  </div>
  <div class="message-footer">
    <span class="message-time" typography variant="body2" color="gray">{{message.sentAt | formatMessageDate}}</span>
  </div>
</div>