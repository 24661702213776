import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Injector,
  OnDestroy,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import {
  blockUi,
  BlockUiService,
} from '../../../../../core/services/block-ui.service';
import {
  markAsSubmitted,
  updateControls,
} from '../../../../../utils/form.util';
import {
  emailValidator,
  maxLengthValidator,
} from '../../../../../utils/validators';
import { ParentAuthenticationDialog } from '../../parent-authentication-dialog';
import { SignUpTermsAndConditionsComponent } from '../sign-up-terms-and-conditions/sign-up-terms-and-conditions.component';
import { SignUpPrivacyPolicyComponent } from '../signup-privacy-policy/sign-up-privacy-policy.component';
import { getUserFriendlyError } from '../../../../../utils/errors';
import { StorageKey } from '../../../../../shared/enums/local-storage-key.enum';
import { StorageService } from '../../../../../core/services/storage.service';
import { BreakpointService } from '../../../../../core/services/breakpoint.service';
import { AuthService } from '../../../../../core/services/auth.service';

/**
 * Component dialog for adding user´s name.
 * It appears after login if user does not exists in mongo database.
 */
@Component({
  selector: 'app-addition-form',
  templateUrl: './addition-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdditionFormComponent
  extends ParentAuthenticationDialog
  implements OnDestroy
{
  form: UntypedFormGroup;

  /** Large screen flag */
  ls = false;

  /** Observable for sign up errors */
  error$ = new Subject<string>();

  signupType: 'phone' | 'email';
  showConsentCheckboxes = true;

  constructor(
    injector: Injector,
    @Inject(MAT_DIALOG_DATA) private readonly data: any,
    private readonly router: Router,
    private readonly blockUiService: BlockUiService,
    private readonly dialogRef: MatDialogRef<any>,
    private readonly storageService: StorageService,
    private readonly breakpointService: BreakpointService,
    private readonly authService: AuthService,
  ) {
    super(injector);
    this.signupType = data.profile.sub?.startsWith('sms') ? 'phone' : 'email';
    // Do not show consents for sms login if user already agreed
    this.showConsentCheckboxes = !(
      this.signupType === 'phone' &&
      !!this.storageService.getSessionItem(StorageKey.SIGN_UP_CONSENT_AGREE)
    );
    this.storageService.removeSessionItem(StorageKey.SIGN_UP_CONSENT_AGREE);

    this.form = new UntypedFormGroup({
      email: new UntypedFormControl(
        data.profile.email
          ? { value: data.profile.email, disabled: true }
          : undefined,
        {
          updateOn: 'blur',
          asyncValidators: [this.userService.userEmailUniqueValidator()],
          validators: [Validators.required, emailValidator()],
        },
      ),
      firstName: new UntypedFormControl(data.profile.given_name, [
        Validators.required,
        maxLengthValidator(100),
      ]),
      lastName: new UntypedFormControl(data.profile.family_name, [
        Validators.required,
        maxLengthValidator(100),
      ]),
    });
    if (this.signupType === 'phone') {
      this.form.addControl(
        'phone',
        new UntypedFormControl({ value: data.profile.name, disabled: true }),
      );
    }
    if (this.showConsentCheckboxes) {
      this.form.addControl(
        'agreeTermsAndConditions',
        new UntypedFormControl(false, [Validators.requiredTrue]),
      );
      this.form.addControl(
        'agreePrivacyPolicy',
        new UntypedFormControl(false, [Validators.requiredTrue]),
      );
    }
    this.breakpointService.largeScreen$
      .pipe(this.untilDestroyed())
      .subscribe(isLargeScreen => {
        this.ls = isLargeScreen;
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.error$.complete();
  }

  openTermsAndConditions() {
    this.dialogService
      .open(SignUpTermsAndConditionsComponent)
      .afterClosed()
      .subscribe(response => {
        if (response) {
          this.form.controls.agreeTermsAndConditions.reset(true);
        }
      });
  }

  openPrivacyPolicy() {
    this.dialogService
      .open(SignUpPrivacyPolicyComponent)
      .afterClosed()
      .subscribe(response => {
        if (response) {
          this.form.controls.agreePrivacyPolicy.reset(true);
        }
      });
  }

  submit(event: Event) {
    event.preventDefault();
    const controls = this.form.controls;
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      markAsSubmitted(this.form);
      updateControls(this.form);
      return;
    }
    const obs =
      this.signupType === 'phone'
        ? this.userService.createSmsUser(
            controls.email.value,
            controls.firstName.value,
            controls.lastName.value,
          )
        : this.userService.createUser(
            controls.firstName.value,
            controls.lastName.value,
          );

    obs.pipe(blockUi(this.blockUiService)).subscribe(
      user => {
        this.authService.refreshToken$.next();

        this.dialogRef.close({ user });
        this.error$.next(null);
      },
      error => {
        this.error$.next(getUserFriendlyError(error));
      },
    );
  }
}
