import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  Host,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import {
  ControlContainer,
  UntypedFormControl,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  NgForm,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ParentComponent } from './parent.component';
import { BehaviorSubject } from 'rxjs';

export class TextAreaErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    return !!(control && control.invalid && control.touched);
  }
}

@Component({
  selector: 'input-textarea',
  template: `
    <mat-form-field [class.input-full-width]="fullWidth" [style.]="fullWidth">
      <mat-label>{{ labelVisible && label ? label : '' }}</mat-label>
      <textarea
        #input
        matInput
        [formControl]="formControl"
        [type]="type"
        [errorStateMatcher]="matcher"
        [rows]="rows"
        [placeholder]="placeholder"
        [cdkTextareaAutosize]="autosize"
        [class.input-textarea-autosize]="autosize"
        [class.input-label-empty]="!label || !labelVisible"
        [maxlength]="maxlength"
        dataTest="textarea"
        (focus)="onFocus()"
      ></textarea>
      <mat-error *ngIf="errorMessage$ | async as errorMessage">
        {{ this.errorMessage }}
      </mat-error>
      <mat-hint *ngIf="displayLength && maxlength">
        {{ textLengthSubject | async }}/{{ maxlength }} characters used
      </mat-hint>
      <div *ngIf="suffix" matSuffix>
        <a class="input-suffix" href="#">{{ suffix }}</a>
      </div>
    </mat-form-field>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextAreaComponent),
      multi: true,
    },
  ],
})
export class InputTextAreaComponent
  extends ParentComponent
  implements OnInit, OnDestroy, AfterContentChecked
{
  @Input() placeholder?: string;

  @Input() suffix?;

  @Input() autosize = false;

  @Input() labelVisible = true;

  @Input()
  type = 'text';

  @Input()
  rows = 4;

  @Input()
  maxlength?: number;

  @Input()
  displayLength?: boolean;

  /** Variable Rows */
  @Input()
  variableRows = false;
  // maximum number of rows to show, passed from "rows" parameter
  maxRows: number;
  // needed to access logic in ngAfterContentChecked()
  // the first time, it is called after ngOnInit()
  init = true;
  // height of each row in px
  rowHeight: number;
  // padding of textarea element
  elemPadding: number;
  /** ------------- */

  matcher = new TextAreaErrorStateMatcher();

  @ViewChild('input', { static: false })
  input: ElementRef<HTMLInputElement>;

  textLengthSubject: BehaviorSubject<number>;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    @Host() @SkipSelf() @Optional() parentControlContainer: ControlContainer,
  ) {
    super(parentControlContainer);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.renderer.addClass(this.elementRef.nativeElement, 'input');
    this.renderer.addClass(this.elementRef.nativeElement, 'input-textarea');
    this.textLengthSubject = new BehaviorSubject<number>(
      this.getTextLength(this.formControl.value),
    );
    this.formControl.valueChanges.subscribe(value => {
      this.textLengthSubject.next(this.getTextLength(value));
    });
    // initiate description with only one line
    if (this.variableRows) {
      this.maxRows = this.rows;
      this.rows = 1;
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.textLengthSubject.complete();
  }

  /**
   * proceed only if this element has focus or is run for the first time
   */
  ngAfterContentChecked() {
    if (
      !this.input ||
      (document.activeElement.id !== this.input.nativeElement.id && !this.init)
    ) {
      return;
    }
    const inputEl = this.input.nativeElement;
    if (this.init) {
      if (this.variableRows) {
        this.calculateRowHeight(inputEl);
        this.setRowsAndAutosize(inputEl);
      }
    }

    if (this.maxlength && inputEl.value.length > this.maxlength) {
      inputEl.value = inputEl.value.substring(0, this.maxlength);
    }

    if (this.displayLength) {
      this.textLengthSubject.next(this.getTextLength(inputEl.value));
    }
  }

  /**
   * Called, when textarea element gets focus.
   * Ensures that error disappeares.
   * In case of error other than "empty field", preserve input,
   * which should not even happen, because max length error is checked for
   */
  onFocus() {
    if (this.formControl.invalid) {
      const tmp = this.input.nativeElement.value;
      this.formControl.reset();
      this.input.nativeElement.value = tmp;
    }
  }

  focus() {
    this.input.nativeElement.focus();
  }

  private getTextLength(value: any) {
    if (typeof value === 'string') {
      return value.length;
    }
    return 0;
  }

  private setRowsAndAutosize(inputEl: HTMLInputElement) {
    if (
      inputEl.scrollHeight >
      this.maxRows * this.rowHeight + this.elemPadding
    ) {
      this.rows = this.maxRows;
      this.autosize = false;
    } else {
      this.autosize = true;
      this.rows = 1;
    }
  }

  /**
   * calculate row height and padding of textarea element
   * this is calculated only once, at first call of this method
   */
  private calculateRowHeight(inputEl: HTMLInputElement) {
    const compStyles = window.getComputedStyle(inputEl);
    this.elemPadding =
      parseInt(compStyles.paddingTop.split('px')[0], 10) +
      parseInt(compStyles.paddingBottom.split('px')[0], 10);
    this.rowHeight = inputEl.clientHeight - this.elemPadding;
    this.init = false;
  }
}
