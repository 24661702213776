import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownCountryComponent } from './components/dropdown-country.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TypographyModule } from '@components/typography/typography.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';

@NgModule({
  declarations: [DropdownCountryComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    TypographyModule,
  ],
  exports: [DropdownCountryComponent],
})
export class DropdownCountryLegacyModule {}
