import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { BreakpointService } from '../../../../core/services/breakpoint.service';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { PartnerInfo } from '../../partners.model';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../../../../core/core.module';
import { ComponentApiModule } from '../../../../libs/component-lib/component-api.module';

@Component({
  standalone: true,
  selector: 'app-home-partners-dialog',
  templateUrl: './partners-dialog.component.html',
  styleUrls: ['./partners-dialog.component.scss'],
  imports: [CommonModule, CoreModule, ComponentApiModule],
  encapsulation: ViewEncapsulation.None,
})
export class PartnersDialogComponent {
  largeScreen$: Observable<boolean>;
  partner: string;

  partnerInfo: PartnerInfo;

  constructor(
    private readonly breakpointService: BreakpointService,
    private readonly dialogRef: MatDialogRef<PartnersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: { partner: PartnerInfo },
  ) {
    this.largeScreen$ = this.breakpointService.largeScreen$;
    this.partnerInfo = data.partner;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
