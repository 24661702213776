import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

export type ButtonVariant =
  | 'contained'
  | 'outlined'
  | 'text-clear'
  | 'clear'
  | 'float'
  | 'icon'
  | 'mobile-control'
  | 'link'
  | 'link-active';
export type ButtonSize = 'small' | 'normal' | 'large';
export type ButtonColor =
  | 'primary'
  | 'secondary'
  | 'warning'
  | 'white'
  | 'ink'
  | 'inherit';

@Component({
  selector: '[button]',
  template: `
    <ng-content></ng-content>
    <span
      class="button-loading-indicator"
      data-test="edit-address"
      *ngIf="loading"
    >
      <mat-spinner [diameter]="24"></mat-spinner>
    </span>
  `,
})
export class ButtonComponent implements OnInit, OnChanges {
  @Input() variant: ButtonVariant = 'contained';

  @Input() size: ButtonSize = 'normal';

  @Input() color: ButtonColor = 'primary';

  @Input() fullWidth: boolean;

  @Input() loading: boolean;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.renderer.addClass(this.elementRef.nativeElement, `button`);
    this.renderer.addClass(
      this.elementRef.nativeElement,
      `button-variant-${this.variant}`,
    );
    this.renderer.addClass(
      this.elementRef.nativeElement,
      `button-size-${this.size}`,
    );
    this.renderer.addClass(
      this.elementRef.nativeElement,
      `button-color-${this.color}`,
    );
    if (this.fullWidth) {
      this.renderer.addClass(
        this.elementRef.nativeElement,
        'button-full-width',
      );
    }
    if (this.loading) {
      this.renderer.addClass(this.elementRef.nativeElement, 'button-loading');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const element = this.elementRef.nativeElement;
    if (changes.loading) {
      if (changes.loading.currentValue) {
        this.renderer.addClass(element, 'button-loading');
      } else {
        this.renderer.removeClass(element, 'button-loading');
      }
    }
    if (changes.variant) {
      this.renderer.removeClass(
        element,
        'button-variant-' + changes.variant.previousValue,
      );
      this.renderer.addClass(
        element,
        'button-variant-' + changes.variant.currentValue,
      );
    }
    if (changes.color) {
      this.renderer.removeClass(
        element,
        'button-color-' + changes.color.previousValue,
      );
      this.renderer.addClass(
        element,
        'button-color-' + changes.color.currentValue,
      );
    }
  }
}
