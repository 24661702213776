import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { InputTaxJurisdictionComponent } from './components/input-tax-jurisdiction.component';
import { TypographyModule } from '@components/typography/typography.module';
import { IconModule } from '@components/icon/icon.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';

@NgModule({
  declarations: [InputTaxJurisdictionComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    TypographyModule,
    IconModule,
    MatAutocompleteModule,
  ],
  exports: [InputTaxJurisdictionComponent],
})
export class InputTaxJurisdictionLegacyModule {}
