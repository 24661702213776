import { NgModule } from '@angular/core';
import { FullCalendarComponent } from './components/full-calendar/full-calendar.component';
import { FullCalendarYearComponent } from './components/full-calendar-year/full-calendar-year.component';
import { CommonModule } from '@angular/common';
import { TypographyModule } from '@components/typography/typography.module';
import { IconModule } from '@components/icon/icon.module';

@NgModule({
  imports: [CommonModule, TypographyModule, IconModule],
  declarations: [FullCalendarComponent, FullCalendarYearComponent],
  exports: [FullCalendarComponent, FullCalendarYearComponent],
})
export class FullCalendarLegacyModule {}
