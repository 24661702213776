import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { TypographyModule } from '@components/typography/typography.module';
import { InputTextAreaComponent } from './components/input-textarea.component';

@NgModule({
  declarations: [InputTextAreaComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    TypographyModule,
  ],
  exports: [InputTextAreaComponent],
})
export class InputTextareaLegacyModule {}
