import { NgModule } from '@angular/core';
import { RadioButtonComponent } from './components/radio-button.component';
import { TypographyModule } from '@components/typography/typography.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [RadioButtonComponent],
  imports: [CommonModule, TypographyModule],
  exports: [RadioButtonComponent],
})
export class RadioButtonLegacyModule {}
