<ng-container *ngIf="user$ | async as user">
  <div class="chat-window">
    <div class="chat-window-header">
      <div class="d-flex">
        <button button variant="icon" class="mr-8">
          <icon variant="chevron_left"></icon>
        </button>
        <p typography variant="subheading2" style="margin-top: 2px">{{getRecipient(conversation, user) | userFullName}}</p>
      </div>
      <div class="d-flex ml-auto">
        <button button variant="icon" (click)="onMaximize()">
          <icon variant="maximize"></icon>
        </button>
        <button button variant="icon" class="ml-8" (click)="onClose()">
          <icon variant="cross"></icon>
        </button>
      </div>
    </div>
    <app-chat-messages [conversation]="conversation" class="chat-window-messages"></app-chat-messages>
  </div>

</ng-container>
