import { PropertyImage } from './property';
import { ClientInfo } from './user.model';
import { BookingInterval, BookingStatus } from './booking';
import { Address } from './address.model';

export interface Integration {
  id: string;
  clientId: string;
  /** @deprecated */
  clientSecret?: string;
  propertyOwnerId: string;
  integrationPartner: IntegrationPartner;
  isConnected: boolean;
  integratedProperties: number;
  available: boolean;
  integrationStatus: IntegrationStatus;
  additionalInformation?: AdditionalInformation;
  propertyTemplate?: {
    autoPublish?: boolean;
    autoWithdrawal?: {
      automatic?: boolean;
      bankAccountId?: string;
      applyToNewOnly?: boolean;
    };
  };
}

export interface AdditionalInformation {
  name?: string;
  propertyManagementSystem?: string;

  hostfully?: HostfullyAdditionalInfo;
  streamline?: StreamlineAdditionalInfo;
}

export interface StreamlineAdditionalInfo {
  tokenExpiration: Date;
  advertiserName: string;
}

export interface HostfullyAdditionalInfo {
  name?: string;
  email?: string;
  phoneNumber?: string;
  address?: Address;
  website?: string;
  defaultCheckInHour?: number;
  defaultCheckOutHour?: number;
  rentalCondition?: string;
}

export enum IntegrationStatus {
  CREATED = 'created',
  PENDING = 'pending',
  IMPORTING = 'importing',
  CONNECTED = 'connected',
}

export interface IntegrationClientCredentials {
  hostaway?: {
    apiKey: string;
  };
  streamline?: {
    tokenKey: string;
    tokenSecret: string;
  };
}

export interface HostfullyInfo {
  name: string;
  existOnHostfully: boolean;
  associatedWithSameUser: boolean;
  activatedOnHostfully: boolean;
  integration: Integration;
}

export interface IntegrationModel {
  id: string;
  name: string;
  image: string;
  urlEdit: string;
  urlWizard: string;
  state?: IntegratorState;
}

export enum IntegratorState {
  READY_TO_CONNECT = 'Not connected',
  CONNECTED = 'Connected',
  PENDING = 'Pending',
  DISCONNECT = 'Disconnected',
  ERROR = 'ERROR',
  SHOW_ONLY = '-',
  UNAVAILABLE = 'Partner is temporary not available',
}

export enum IntegrationPartner {
  HOST_FULLY = 'HOST_FULLY',
  NEXT_PAX = 'NEXT_PAX',
  HOST_AWAY = 'HOST_AWAY',
  STREAMLINE = 'STREAMLINE',
  BOOST = 'BOOST',
}
