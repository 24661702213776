import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ToggleName, ToggleService } from '../modules/toggle/toggle.service';

/**
 * Directive for enabling/disabling component section based on toggle
 *
 * @example
 *  *toggle="'superhog'" -> display block, only if superhog toggle is enabled
 *  *toggle="'!superhog'"-> display block, only if superhog toggle is disabled
 *
 * @author Libor Staněk
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[toggle]',
})
export class ToggleDirective implements OnInit, OnDestroy {
  @Input()
  toggle: ToggleName | `!${ToggleName}`;

  private toggleSubscription: Subscription;
  private show: boolean;

  constructor(
    private readonly element: ElementRef,
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly toggleService: ToggleService,
  ) {}

  ngOnInit(): void {
    const invertToggle = this.toggle.startsWith('!');
    const toggle = (
      invertToggle ? this.toggle.substring(1) : this.toggle
    ) as ToggleName;
    this.toggleSubscription = this.toggleService
      .isEnabled$(toggle)
      .subscribe(enabled => {
        const show = invertToggle ? !enabled : enabled;
        if (this.show === show) {
          return;
        }
        if (show) {
          this.viewContainer
            .createEmbeddedView(this.templateRef)
            .detectChanges();
        } else {
          this.viewContainer.clear();
        }
        this.show = show;
      });
  }

  ngOnDestroy(): void {
    if (this.toggleSubscription) {
      this.toggleSubscription.unsubscribe();
    }
  }
}
