import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { TypographyModule } from '@components/typography/typography.module';
import { AddressComponent } from './components/address.component';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { IconModule } from '@components/icon/icon.module';
import { InputAddressComponent } from './components/input-address.component';

@NgModule({
  declarations: [AddressComponent, InputAddressComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    TypographyModule,
    IconModule,
  ],
  exports: [AddressComponent, InputAddressComponent],
})
export class InputAddressLegacyModule {}
