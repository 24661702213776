import { Pipe, PipeTransform } from '@angular/core';
import { Property, PropertyImage } from '../../shared/models/property';

@Pipe({
  name: 'propertyFeaturedPhoto',
})
export class PropertyFeaturedPhotoPipe implements PipeTransform {
  transform(property: Property, args?: any): PropertyImage {
    return property?.propertyImages.find(image => image.featured);
  }
}

@Pipe({ name: 'thumbnail' })
export class ThumbnailPipe implements PipeTransform {
  transform(url: string, args?: any): string {
    return url ? url + '_thumbnail' : null;
  }
}
