import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthService } from '../../../../../core/services/auth.service';
import { BlockUiService } from '../../../../../core/services/block-ui.service';
import { BreakpointService } from '../../../../../core/services/breakpoint.service';
import { DialogService } from '../../../../../core/services/dialog.service';
import { getUserFriendlyError } from '../../../../../utils/errors';
import {
  markAsSubmitted,
  updateControls,
} from '../../../../../utils/form.util';
import {
  emailValidator,
  maxLengthValidator,
  passwordValidator,
} from '../../../../../utils/validators';
import { ParentAuthenticationDialog } from '../../parent-authentication-dialog';
import { SignUpTermsAndConditionsComponent } from '../sign-up-terms-and-conditions/sign-up-terms-and-conditions.component';
import { SignUpPrivacyPolicyComponent } from '../signup-privacy-policy/sign-up-privacy-policy.component';
import { RedirectService } from '../../../../../core/services/redirect.service';
import { UploadPhotoComponent } from '../../post-registration-dialogs/upload-photo/upload-photo.component';
import { LoginFormComponent } from '../../login/form/login-form.component';
import { GoogleAnalytics4Service } from '../../../../../core/services/google-analytics4.service';

/**
 * Component dialog for registration into Auth0
 */
@Component({
  selector: 'app-signup-form',
  templateUrl: './sign-up-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignUpFormComponent
  extends ParentAuthenticationDialog
  implements OnInit, OnDestroy
{
  form: UntypedFormGroup;

  /** Large screen flag */
  ls = false;

  /** Observable for sign up errors */
  error$ = new Subject<string>();

  @Input() isDialog = true;

  constructor(
    injector: Injector,
    private readonly router: Router,
    private readonly redirectService: RedirectService,
    private readonly authService: AuthService,
    private readonly blockUiService: BlockUiService,
    private readonly breakpointService: BreakpointService,
    private readonly changeDetection: ChangeDetectorRef,
    readonly dialogService: DialogService,
    protected readonly ga4: GoogleAnalytics4Service,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl('', {
        updateOn: 'blur',
        asyncValidators: [this.userService.userEmailUniqueValidator()],
        validators: [Validators.required, emailValidator()],
      }),
      password: new UntypedFormControl('', [
        Validators.required,
        passwordValidator(),
      ]),
      firstName: new UntypedFormControl('', [
        Validators.required,
        maxLengthValidator(100),
      ]),
      lastName: new UntypedFormControl('', [
        Validators.required,
        maxLengthValidator(100),
      ]),
      agreeTermsAndConditions: new UntypedFormControl(false, [
        Validators.requiredTrue,
      ]),
      agreePrivacyPolicy: new UntypedFormControl(false, [
        Validators.requiredTrue,
      ]),
    });

    this.breakpointService.largeScreen$
      .pipe(this.untilDestroyed())
      .subscribe(isLargeScreen => {
        this.ls = isLargeScreen;
        this.changeDetection.detectChanges();
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.error$.complete();
  }

  openTermsAndConditions() {
    this.dialogService
      .open(SignUpTermsAndConditionsComponent)
      .afterClosed()
      .subscribe(response => {
        if (response) {
          this.form.controls.agreeTermsAndConditions.reset(true);
        }
      });
  }

  openPrivacyPolicy() {
    this.dialogService
      .open(SignUpPrivacyPolicyComponent)
      .afterClosed()
      .subscribe(response => {
        if (response) {
          this.form.controls.agreePrivacyPolicy.reset(true);
        }
      });
  }

  /**
   * Sign up of user is performed on backend, if it is done correctly
   * user is sign in automatically and without need of verification
   */
  submit(event: Event) {
    event.preventDefault();
    const controls = this.form.controls;
    if (
      this.form.invalid ||
      !controls.agreeTermsAndConditions?.value ||
      !controls.agreePrivacyPolicy?.value
    ) {
      this.form.markAllAsTouched();
      markAsSubmitted(this.form);
      updateControls(this.form);
      return;
    }
    this.authService
      .signUpEmail(
        controls.email.value,
        controls.password.value,
        controls.firstName.value,
        controls.lastName.value,
      )
      .subscribe({
        next: user => {
          this.error$.next(null);
          if (!this.router.url.includes('bookings')) {
            this.redirectService.navigate();
          }
          if (user.isNewUser) {
            this.dialogService.clearAndOpen(UploadPhotoComponent);
          }
          // Google Analytics SignUp Conversion with callback
          this.ga4.sendSignUpConversion();
        },
        error: error => {
          this.error$.next(getUserFriendlyError(error));
        },
      });
  }
}

@Component({
  selector: 'app-signup-form-link',
  template: ` <button
    button
    type="button"
    variant="contained"
    color="secondary"
    class="w-100 mb-16"
    (click)="signUpWithEmail()"
  >
    <ng-content></ng-content>
  </button>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignUpFormLinkComponent {
  @Input() isDialog = true;

  constructor(
    private readonly router: Router,
    private readonly dialogService: DialogService,
  ) {}

  signUpWithEmail() {
    if (!this.isDialog) {
      this.router.navigate(['/sign-up/email'], {
        queryParamsHandling: 'preserve',
      });
    } else {
      this.dialogService.clearAndOpen(SignUpFormComponent);
    }
  }
}

/**
 * Sign-In link, opens the Login dialog.
 */
@Component({
  selector: 'app-signin-link',
  template: `
    <a link variant="link1" color="primary" (click)="openSignIn()">
      <ng-content></ng-content>
    </a>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignInEmailComponent {
  @Input() isDialog = true;

  constructor(
    private readonly router: Router,
    private readonly dialogService: DialogService,
  ) {}

  openSignIn() {
    if (!this.isDialog) {
      this.router.navigate(['/login/email']);
    } else {
      this.dialogService.clearAndOpen(LoginFormComponent);
    }
  }
}
