import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AbstractComponent } from '../../../../../core/components/abstract/abstract.component';
import { ChatFileDialogComponent } from '../chat-dialogs/chat-file-dialog/chat-file-dialog.component';
import { ChatService } from '../../../services/chat.service';
import { NotificationService } from '../../../../../core/services/notification.service';
import { DialogService } from '../../../../../core/services/dialog.service';
import { ChatTemplateDialogComponent } from '../chat-dialogs/chat-template-dialog/chat-template-dialog.component';
import { TemplateService } from '../../../services/template.service';
import { ChatArchiveDialogComponent } from '../chat-dialogs/chat-archive-dialog/chat-archive-dialog.component';
import { ChatUtils } from '../../../utils/chat.utils';
import { UserService } from '../../../../../core/services/user.service';
import { Conversation } from '../../../models/conversation.model';
import { MessageType } from '../../../models/message.model';
import { InquiryInfo } from '../../../models/text-message.model';

@Component({
  selector: 'app-chat-input',
  templateUrl: './chat-input.component.html',
  styleUrls: ['./chat-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatInputComponent
  extends AbstractComponent
  implements OnInit, OnDestroy
{
  /** Show send icon in view */
  sendIcon$: Observable<boolean>;
  formGroup: UntypedFormGroup;
  sendingMessage = false;

  inquiryInfoSubscriber: Subscription;
  inquiryInfo: InquiryInfo = null;

  private readonly MAX_FILE_SIZE = 5 * 1024 * 1024;
  private readonly MAX_FILE_AMOUNT = 5;

  @Input()
  conversation: Conversation;

  templatesEnabled = false;
  fileUploadEnabled = false;

  moreMenuOpen = false;

  isArchived = ChatUtils.isArchived;

  user$ = this.userService.getCurrentUser();

  constructor(
    private readonly chatService: ChatService,
    private readonly userService: UserService,
    private readonly dialogService: DialogService,
    private readonly notificationService: NotificationService,
    private readonly templateService: TemplateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.formGroup = new UntypedFormGroup({
      text: new UntypedFormControl(undefined),
    });
    this.inquiryInfoSubscriber = this.chatService.inquiryInfo$.subscribe(
      val => {
        this.inquiryInfo = val;
      },
    );

    this.sendIcon$ = this.formGroup.controls.text.valueChanges.pipe(
      map(text => this.isValidText(text)),
    );

    this.templateService.templateSelectedSubject
      .pipe(this.untilDestroyed())
      .subscribe(value => {
        this.formGroup.controls.text.setValue(value);
      });
  }

  ngOnDestroy(): void {
    this.inquiryInfoSubscriber.unsubscribe();
  }

  onTextKeyDown(event: any) {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      const modificationKey = event.ctrlKey || event.shiftKey || event.altKey;
      if (!modificationKey) {
        event.preventDefault();
        this.onTextSubmit();
      }
      if (event.ctrlKey || event.altKey) {
        // If browser supports `setRangeText` function, allow user to create new line using CTRL or ALT key
        const caret = event.target.selectionStart;
        if (typeof event.target.setRangeText === 'function') {
          event.target.setRangeText('\n', caret, caret, 'end');
        }
      }
    }
  }

  openTemplates() {
    this.dialogService.open(ChatTemplateDialogComponent);
  }

  onTextSubmit() {
    if (!this.conversation.id || this.sendingMessage) {
      return;
    }
    const text = this.formGroup.controls.text.value as string;
    // Do not send empty text
    if (!this.isValidText(text)) {
      return;
    }
    this.sendingMessage = true;

    this.chatService
      .sendTextMessage(
        this.conversation.id,
        this.inquiryInfo ? MessageType.INQUIRY : MessageType.TEXT,
        text,
        this.inquiryInfo,
      )
      .subscribe({
        next: () => {
          this.sendingMessage = false;
          this.formGroup.controls.text.reset();
        },
        error: err => {
          this.sendingMessage = false;
          this.notificationService.showNotification({
            text: 'Failed to send message.',
            color: 'error',
            duration: 4000,
          });
        },
      });
  }

  isValidText(text: string): boolean {
    if (!text) {
      return false;
    }
    if (!text.trim()) {
      return false;
    }
    return true;
  }

  fileChange(event): void {
    let files: any[] = Array.from(event.target.files);
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > this.MAX_FILE_SIZE) {
        this.notificationService.showNotification({
          text: `File '${files[i].name}' is too large, maximum is ${
            this.MAX_FILE_SIZE / (1024 * 1024)
          }MB!`,
          color: 'error',
        });
        files.splice(i, 1);
      }
    }
    if (files.length > this.MAX_FILE_AMOUNT) {
      files = files.slice(0, this.MAX_FILE_AMOUNT);
      this.notificationService.showNotification({
        text: 'Too many files! Only the first five are taken into account',
        color: 'error',
      });
    }
    if (files.length < 1) {
      return;
    }
    this.dialogService
      .open(ChatFileDialogComponent, {
        data: {
          files: files,
        },
      })
      .afterClosed()
      .pipe(this.untilDestroyed())
      .subscribe(() => {
        event.target.value = '';
      });
  }

  toggleMoreMenu() {
    this.moreMenuOpen = !this.moreMenuOpen;
  }

  archiveConversation(conversation: Conversation) {
    this.toggleMoreMenu();
    this.dialogService.open(ChatArchiveDialogComponent, {
      data: {
        conversation: conversation,
      },
    });
  }
}
