<ng-template #tooltipContent>
  <ng-content select="[tooltip]"></ng-content>
</ng-template>

<div class="message d-flex justify-content-center" [class.message-by-current-user]="message.sender.isCurrentUser">
  <div *ngIf="property" class="message-content d-flex align-items-center">
    <badge class="justify-content-center" [tp]="tooltipContent" [tpIsEnabled]="isTooltipEnabled" size="small"
      [color]="color" [icon]="icon" variant="light">
      <div>
        <span *ngIf="infoTextBefore" class="message-text-before">{{infoTextBefore}}&nbsp;</span><a link variant="link2"
          [color]="color" (click)="navigate()">{{linkText}}</a>
        <span *ngIf="property">&nbsp;on {{property.headline}}</span>
        <span *ngIf="interval">&nbsp;({{interval.checkIn | date}} - {{interval.checkOut | date}})</span>
        <span *ngIf="infoTextAfter" class="message-text-after">&nbsp;{{infoTextAfter}}</span>
      </div>
    </badge>
    <div *ngIf="!message.payload.text" class="message-time" typography variant="body2" color="gray">{{message.sentAt |
      formatMessageDate}}</div>
  </div>
</div>

<div *ngIf="message.payload.text" class="mt-16">
  <app-chat-message-text [message]="message" [text]="text"></app-chat-message-text>
</div>