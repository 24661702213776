import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

export type TypographyVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'subheading1'
  | 'subheading2'
  | 'subheading3'
  | 'body1'
  | 'weight-body1'
  | 'body2'
  | 'body3'
  | 'button'
  | 'link1'
  | 'link2'
  | 'link3'
  | 'navbarLink';
type TypographyAlign = 'inherit' | 'left' | 'center' | 'right' | 'justify';
type TypographyTransform = 'uppercase';
export type TypographyColor =
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'warning'
  | 'gray'
  | 'darkgray'
  | 'black'
  | 'white'
  | 'error'
  | 'success';

@Component({
  selector: '[typography]',
  template: '<ng-content></ng-content>',
})
export class TypographyComponent implements OnInit, OnChanges {
  @Input()
  variant: TypographyVariant = 'body1';

  @Input()
  textAlign: TypographyAlign;

  @Input()
  transform: TypographyTransform;

  @Input()
  color: TypographyColor;

  @Input()
  gutterBottom: boolean;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.renderer.addClass(this.elementRef.nativeElement, `typography`);
    this.renderer.addClass(
      this.elementRef.nativeElement,
      `typography-variant-${this.variant}`,
    );
    if (this.textAlign) {
      this.renderer.addClass(
        this.elementRef.nativeElement,
        `typography-align-${this.textAlign}`,
      );
    }
    if (this.transform) {
      this.renderer.addClass(
        this.elementRef.nativeElement,
        `typography-transform-${this.transform}`,
      );
    }
    if (this.color) {
      this.renderer.addClass(
        this.elementRef.nativeElement,
        `typography-color-${this.color}`,
      );
    }
    if (this.gutterBottom) {
      this.renderer.addClass(
        this.elementRef.nativeElement,
        `typography-gutter-bottom`,
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.color) {
      this.renderer.removeClass(
        this.elementRef.nativeElement,
        `typography-color-${changes.color.previousValue}`,
      );
      this.renderer.addClass(
        this.elementRef.nativeElement,
        `typography-color-${changes.color.currentValue}`,
      );
    }
  }
}
