import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionComponent } from './accordion.component';
import { IconModule } from '@components/icon/icon.module';
import { TypographyModule } from '@components/typography/typography.module';
import { TippyDirective } from '@ngneat/helipopper';
import initTooltip from '../../component-lib/utils/init-tooltip';

@NgModule({
  declarations: [AccordionComponent],
  exports: [AccordionComponent],
  imports: [CommonModule, IconModule, TippyDirective, TypographyModule],
  providers: [initTooltip()],
})
export class AccordionModule {}
