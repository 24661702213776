import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AbstractComponent } from '../../../core/components/abstract/abstract.component';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'property-search-destination-dialog',
  template: `
    <dialog-wrapper position="bottom" layout="content">
      <dialog-content class="h-100">
        <div class="input-location-fullscreen">
          <div class="input-location-fullscreen-header">
            <button
              button
              icon="arrow_left"
              class="mx-8"
              variant="text"
              iconColor="ink"
              (click)="back()"
            ></button>
            <span typography variant="body1">Where to?</span>
          </div>
          <div class="input-location-fullscreen-locations">
            <input-location
              [formControl]="formControl"
              label="Destination"
              [showInFullscreen]="true"
            ></input-location>
            <div class="continue">
              <button button (click)="continue()" class="m-16 flex-grow-1">
                Continue
              </button>
            </div>
          </div>
        </div>
      </dialog-content>
    </dialog-wrapper>
  `,
  styleUrls: ['property-search-destination-dialog.component.scss'],
})
export class PropertySearchDestinationDialogComponent
  extends AbstractComponent
  implements OnInit
{
  formControl: UntypedFormControl;

  constructor(
    private readonly dialogRef: MatDialogRef<PropertySearchDestinationDialogComponent>,
  ) {
    super();
    this.formControl = new UntypedFormControl(undefined);
  }

  ngOnInit(): void {
    this.formControl.valueChanges
      .pipe(this.untilDestroyed())
      .subscribe(value => {
        if (value?.text) {
          this.dialogRef.close(value);
        }
      });
  }

  continue() {
    this.dialogRef.close({
      text: undefined,
      address: undefined,
    });
  }

  back() {
    this.dialogRef.close();
  }
}
