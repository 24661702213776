<dialog-wrapper-legacy width="sm" [position]="(largeScreen$ | async) == true ? 'center' : 'bottom'">
  <dialog-header (dialogClose)="closeDialog()" title="Archiving chat"></dialog-header>
  <dialog-content-legacy>
    Do you really want to archive this chat?
  </dialog-content-legacy>
  <dialog-actions-legacy>
    <ng-container *ngIf="largeScreen$ | async; else mobileActions">
      <div class="d-flex">
        <button button (click)="archiveConversation(data.conversation)" [fullWidth]="true">Yes, move to archived</button>
        <button button variant="outlined" (click)="closeDialog()" class="ml-16">Cancel</button>
      </div>
    </ng-container>
    <ng-template #mobileActions>
      <div class="d-flex justify-content-between">
        <button button variant="outlined" [fullWidth]="true" (click)="closeDialog()">Cancel</button>
        <button button [fullWidth]="true" class="ml-16" (click)="archiveConversation(data.conversation)">Yes, archive</button>
      </div>
    </ng-template>
  </dialog-actions-legacy>
</dialog-wrapper-legacy>
