import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputGuestsComponent } from './input-guests.component';
import {
  MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatLegacyFormFieldModule as MatFormFieldModule,
} from '@angular/material/legacy-form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { OverlayModule } from '@angular/cdk/overlay';
import { InputModule } from '@components/input/input.module';
import { ToggleModule } from '@components/toggle/toggle.module';
import { StepperModule } from '@components/stepper/stepper.module';
import { TypographyModule } from '@components/typography/typography.module';
import { IconModule } from '@components/icon/icon.module';

@NgModule({
  declarations: [InputGuestsComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    OverlayModule,
    InputModule,
    ToggleModule,
    StepperModule,
    TypographyModule,
    IconModule,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: 'auto',
        appearance: 'standard',
        hideRequiredMarker: true,
      },
    },
  ],
  exports: [InputGuestsComponent],
})
export class InputGuestsModule {}
