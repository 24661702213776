import { Component, Inject, Injector } from '@angular/core';
import { AbstractDialog } from '../../../../../../libs/component-lib/components/dialog/abstract-dialog';
import { BreakpointService } from '../../../../../../core/services/breakpoint.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'chat-file-upload-dialog',
  template: `
    <dialog-wrapper-legacy
      width="md"
      [position]="(largeScreen$ | async) ? 'center' : 'bottom'"
    >
      <dialog-header title=" " (dialogClose)="closeDialog()"></dialog-header>
      <dialog-content-legacy>
        <div class="files">
          <div class="file" *ngFor="let file of filesToUpload">
            <icon variant="document" color="white"></icon>
            <span class="mx-4">{{ file.name }}</span>
            <button
              button
              type="button"
              variant="icon"
              (click)="removeFile(file)"
            >
              <icon variant="cross" color="white"></icon>
            </button>
          </div>
        </div>
      </dialog-content-legacy>
      <dialog-actions-legacy>
        <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
          <div class="d-flex">
            <div class="input-text-wrapper">
              <input
                type="text"
                placeholder="Add a caption..."
                formControlName="caption"
                class="input-text"
              />
            </div>
            <button button type="submit" variant="icon" class="ml-12">
              <icon variant="send" color="primary"></icon>
            </button>
          </div>
        </form>
      </dialog-actions-legacy>
    </dialog-wrapper-legacy>
  `,
  styleUrls: ['./chat-file-dialog.component.scss'],
})
export class ChatFileDialogComponent extends AbstractDialog {
  largeScreen$ = this.breakpointService.largeScreen$;
  formGroup: UntypedFormGroup;
  filesToUpload: any[];

  constructor(
    injector: Injector,
    private readonly breakpointService: BreakpointService,
    @Inject(MAT_DIALOG_DATA) data: { files: any[] },
  ) {
    super(injector);
    this.filesToUpload = data.files;
    this.formGroup = new UntypedFormGroup({
      caption: new UntypedFormControl(undefined),
    });
  }

  removeFile(file: any) {
    const indexOf = this.filesToUpload.indexOf(file);
    this.filesToUpload.splice(indexOf, 1);
    if (this.filesToUpload.length < 1) {
      this.closeDialog();
    }
  }

  onSubmit() {
    console.log(this.formGroup.controls.caption.value);
    this.filesToUpload.forEach(file => {
      console.log(file);
    });
  }
}
