import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserReservationsCalendarComponent } from './user-reservations-calendar.component';
import { ComponentApiModule } from '../../../../libs/component-lib/component-api.module';

@NgModule({
  declarations: [UserReservationsCalendarComponent],
  imports: [CommonModule, ComponentApiModule],
  exports: [UserReservationsCalendarComponent],
})
export class UserReservationsCalendarModule {}
