<dialog-wrapper-legacy width="sm" [position]="ls ? 'center' : 'bottom'">
  <dialog-header (dialogClose)="closeDialog()" title="Your profile picture"></dialog-header>
  <dialog-content-legacy>
    <div class="d-flex justify-content-center cropper-height">
      <image-cropper
        [imageFile]="file"
        [maintainAspectRatio]="true"
        [aspectRatio]="1"
        [format]="format"
        (cropperReady)="onCropperReady($event)"
        (imageCropped)="imageCropped($event)"
        [cropperMinWidth]="minWidth"
        [resizeToWidth]="minWidth"
        [cropper]="cropper"
        roundCropper="true"
        onlyScaleDown="true"
      ></image-cropper>
    </div>
  </dialog-content-legacy>
  <dialog-actions-legacy>
    <div class="d-flex justify-content-center mx-16">
      <button
        button
        appMouseHold
        variant="icon"
        color="primary"
        class="pb-8"
        data-test="avatar-size-minus"
        (mousehold)="decreaseDimension()"
      >
        <icon variant="minus" size="medium-large"></icon>
      </button>
      <mat-slider
        class="slider slider-primary mb-8 flex-grow-1"
        [min]="minDimension"
        [max]="maxDimension"
        [(value)]="dimension"
        (input)="dimensionSliderChange($event)"
      ></mat-slider>
      <button
        button
        appMouseHold
        variant="icon"
        class="pb-8"
        data-test="avatar-size-plus"
        (mousehold)="increaseDimension()"
      >
        <icon variant="plus" size="medium-large"></icon>
      </button>
    </div>
    <div class="reg-container-half-btn">
      <div class="pr-8">
        <button
          button
          color="primary"
          variant="outlined"
          [fullWidth]="true"
          (click)="closeDialog()"
        >
          Cancel
        </button>
      </div>
    </div>
    <div class="reg-container-half-btn">
      <div class="pl-8">
        <button button color="primary" variant="contained" data-test="button-continue" [fullWidth]="true" (click)="save()">
          Confirm
        </button>
      </div>
    </div>
  </dialog-actions-legacy>
</dialog-wrapper-legacy>
