import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { updateControls } from '../../../../../utils/form.util';
import { emailValidator } from '../../../../../utils/validators';
import { ParentAuthenticationDialog } from '../../parent-authentication-dialog';
import { getUserFriendlyError } from '../../../../../utils/errors';
import { ResetPasswordConfirmationDialogComponent } from './reset-password-confirmation-dialog.component';
import { Router } from '@angular/router';

/**
 * Component dialog for changing password of user´s auth0 account
 */
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordComponent
  extends ParentAuthenticationDialog
  implements OnInit, OnDestroy
{
  form: UntypedFormGroup;
  /** Observable for reset password errors */
  error$ = new Subject<string>();

  @Input() isDialog = true;

  constructor(injector: Injector, private readonly router: Router) {
    super(injector);
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl('', [
        Validators.required,
        emailValidator(),
      ]),
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  handleBackButton(): void {
    if (!this.isDialog) {
      this.router.navigate(['/login/email']);
    } else {
      this.closeDialog();
    }
  }

  submit(event: Event) {
    event.preventDefault();
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      updateControls(this.form);
      return;
    }
    this.userService.resetPassword(this.form.controls.email.value).subscribe(
      () => {
        this.error$.next(null);
        this.dialogService
          .open(ResetPasswordConfirmationDialogComponent, {
            data: {
              email: this.form.controls.email.value,
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.router.navigate(['/login']);
          });
      },
      error => {
        let errorMessage = getUserFriendlyError(error);
        if (error?.error?.statusCode === 404) {
          errorMessage =
            'We were unable to find your email address in our database, please double check your email address or sign up.';
        }
        this.error$.next(errorMessage);
      },
    );
  }
}
