import { Injectable } from '@angular/core';
import {
  ChatTag,
  ChatTemplate,
} from '../components/chat/chat-dialogs/chat-template-dialog/chat-template.model';
import { StorageKey } from '../../../shared/enums/local-storage-key.enum';
import { DialogService } from '../../../core/services/dialog.service';
import { ChatTemplateDialogComponent } from '../components/chat/chat-dialogs/chat-template-dialog/chat-template-dialog.component';
import { NotificationService } from '../../../core/services/notification.service';
import { Subject } from 'rxjs';
import { StorageService } from '../../../core/services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  private templates: ChatTemplate[] = [];

  tags: ChatTag[] = [
    {
      label: 'Property name',
      tag: '{property_name}',
    },
    {
      label: 'Property address',
      tag: '{property_address}',
    },
    {
      label: 'Host name',
      tag: '{host_name}',
    },
    {
      label: 'Host surname',
      tag: '{host_surname}',
    },
    {
      label: 'Guest name',
      tag: '{guest_name}',
    },
    {
      label: 'Guest surname',
      tag: '{guest_surname}',
    },
  ];

  templateSelectedSubject = new Subject<string>();

  constructor(
    private readonly dialogService: DialogService,
    private readonly notificationService: NotificationService,
    private readonly storageService: StorageService,
  ) {
    try {
      this.templates = JSON.parse(
        this.storageService.getItem(StorageKey.CHAT_TEMPLATES) ?? '[]',
      );
    } catch (e) {
      this.templates = [];
      this.saveToLocalStorage();
    }
  }

  getTemplates(): ChatTemplate[] {
    return this.templates;
  }

  saveTemplate(oldLabel: string, template: ChatTemplate): boolean {
    if (
      oldLabel !== template.label &&
      this.templates.find(tmp => tmp.label === template.label)
    ) {
      this.notificationService.showNotification({
        text: 'Template with this name already exists!',
        color: 'error',
      });
      return false;
    }
    this.templates[this.templates.findIndex(tmp => tmp.label === oldLabel)] =
      template;
    this.saveToLocalStorage();
    this.dialogService.clearAndOpen(ChatTemplateDialogComponent);
    return true;
  }

  saveNewTemplate(template: ChatTemplate): boolean {
    if (this.templates.find(tmp => tmp.label === template.label)) {
      this.notificationService.showNotification({
        text: 'Template with this name already exists!',
        color: 'error',
      });
      return false;
    }
    this.templates.push(template);
    this.saveToLocalStorage();
    return true;
  }

  removeTemplate(label: string): ChatTemplate[] {
    this.templates = this.templates.filter(element => {
      if (element.label === label) {
        return false;
      }
      return true;
    });
    return this.templates;
  }

  replaceTagsInMessage(message: string): string {
    // TODO: replace message tags with respective attributes
    return message;
  }

  saveToLocalStorage() {
    this.storageService.setItem(
      StorageKey.CHAT_TEMPLATES,
      JSON.stringify(this.templates),
    );
  }
}
