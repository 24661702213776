<ng-container *ngIf="user$ | async as user">
  <div class="messages-input">
    <form [formGroup]="formGroup" (ngSubmit)="onTextSubmit()">
      <div class="d-flex">
        <input type='file' multiple class="d-none" #fileSelector (change)="fileChange($event)"/>
        <button button type="button" variant="icon" class="mr-16" (click)="fileSelector.click()" *ngIf="fileUploadEnabled">
          <icon variant="paper_clip" color="primary" tooltip="Attach file" tooltipPosition="top"></icon>
        </button>
        <button button type="button" variant="icon" class="mr-16" (click)="openTemplates()" *ngIf="templatesEnabled">
          <icon variant="document" color="primary" tooltip="Templates" tooltipPosition="top"></icon>
        </button>
        <div class="input-text-wrapper">
        <textarea
          placeholder="Send a message"
          formControlName="text"
          class="input-text"
          data-test="chat-input"
          cdkTextareaAutosize
          (keydown)="onTextKeyDown($event)"
          [maxLength]="1000"
        ></textarea>
        </div>
        <ng-container *ngIf="sendIcon$ | async; else iconMore">
          <button button type="submit" variant="icon" class="ml-12" [disabled]="sendingMessage">
            <icon variant="send" color="primary"  data-test="chat-send" tooltip="Send a Message" tooltipPosition="top"></icon>
          </button>
        </ng-container>
        <ng-template #iconMore>
          <button button type="button" cdkOverlayOrigin variant="icon" class="ml-12" #moreMenuTrigger="cdkOverlayOrigin" data-test="archive-menu" (click)="toggleMoreMenu()" [disabled]="isArchived(conversation, user)">
            <icon variant="dots" color="primary"></icon>
          </button>
          <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="moreMenuTrigger"
            [cdkConnectedOverlayOpen]="moreMenuOpen"
            (overlayOutsideClick)="toggleMoreMenu()"
          >
            <div class="dropdown-menu">
              <button
                class="menu-item d-block p-12"
                data-test-="archive-conversation"
                (click)="archiveConversation(conversation)"
              >Archive</button>
            </div>
          </ng-template>
        </ng-template>
      </div>
    </form>
  </div>
</ng-container>
