import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  base64ToFile,
  CropperPosition,
  Dimensions,
  ImageCroppedEvent,
  ImageCropperComponent,
} from 'ngx-image-cropper';
import { DialogService } from '../../../../core/services/dialog.service';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacySliderChange as MatSliderChange } from '@angular/material/legacy-slider';
import { BreakpointService } from '../../../../core/services/breakpoint.service';
import { AbstractComponent } from '../../../../core/components/abstract/abstract.component';

const DEFAULT_CHANGE_DIMENSION_BUTTON_VALUE = 10;

@Component({
  selector: 'avatar-editor',
  templateUrl: 'avatar-editor.component.html',
  styles: [
    `
      .cropper-height {
        max-height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarEditorComponent extends AbstractComponent implements OnInit {
  /** Large screen flag */
  ls = false;

  constructor(
    private readonly http: HttpClient,
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly dialogService: DialogService,
    private readonly dialogRef: MatDialogRef<AvatarEditorComponent>,
    private readonly changeDetection: ChangeDetectorRef,
    private readonly breakpointService: BreakpointService,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    super();
    this.file = data.file;
    this.minWidth = data.minWidth ? data.minWidth : 0;
    this.minHeight = data.minHeight ? data.minHeight : 0;
  }

  @ViewChild(ImageCropperComponent)
  imageCropper: ImageCropperComponent;

  minWidth: number;
  minHeight: number;
  maxWidth: number;
  maxHeight: number;
  minDimension: number;
  maxDimension: number;
  dimension: number;

  format = 'png';
  file: File;
  croppedImage: any = '';

  cropper: CropperPosition = {
    x1: 0,
    x2: 0,
    y1: 0,
    y2: 0,
  };

  ngOnInit(): void {
    this.breakpointService.largeScreen$
      .pipe(this.untilDestroyed())
      .subscribe(isLargeScreen => {
        this.ls = isLargeScreen;
        this.changeDetection.detectChanges();
      });
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.refreshDimension();
  }

  saveImage() {
    this.file = null;
  }

  save() {
    this.imageCropper.crop();
    const data: Blob = base64ToFile(this.croppedImage);
    let fileName: string = this.file.name;
    fileName =
      fileName.substring(0, fileName.lastIndexOf('.') + 1) + this.format;
    const fileToReturn = new File([data], fileName, { type: data.type });
    this.dialogRef.close(fileToReturn);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  dimensionSliderChange(event: MatSliderChange) {
    this.newDimension(event.value);
  }

  decreaseDimension() {
    const dimension =
      this.cropper.x2 - this.cropper.x1 - DEFAULT_CHANGE_DIMENSION_BUTTON_VALUE;
    const changed = this.newDimension(
      dimension < this.minDimension ? this.minDimension : dimension,
    );
    if (changed) {
      this.imageCropper.crop();
    }
  }

  increaseDimension() {
    const dimension =
      this.cropper.x2 - this.cropper.x1 + DEFAULT_CHANGE_DIMENSION_BUTTON_VALUE;
    const changed = this.newDimension(
      dimension > this.maxDimension ? this.maxDimension : dimension,
    );
    if (changed) {
      this.imageCropper.crop();
    }
  }

  newDimension(dimension: number): boolean {
    if (
      this.dimension === dimension ||
      dimension < this.minDimension ||
      dimension > this.maxDimension
    ) {
      return false;
    }

    const oldDimension = this.cropper.x2 - this.cropper.x1;
    const xCenter = this.cropper.x1 + oldDimension / 2;
    const yCenter = this.cropper.y1 + oldDimension / 2;

    const xPositions = this.shiftOverflownDimension(
      {
        first: xCenter - dimension / 2,
        second: xCenter + dimension / 2,
      },
      this.maxWidth,
    );
    const yPositions = this.shiftOverflownDimension(
      {
        first: yCenter - dimension / 2,
        second: yCenter + dimension / 2,
      },
      this.maxHeight,
    );

    this.cropper.x1 = xPositions.first;
    this.cropper.x2 = xPositions.second;
    this.cropper.y1 = yPositions.first;
    this.cropper.y2 = yPositions.second;

    this.refreshDimension();
    return true;
  }

  shiftOverflownDimension(
    positions: { first: number; second: number },
    maxDimension: number,
  ) {
    if (positions.first < 0) {
      positions.second -= positions.first;
      positions.first = 0;
    }
    if (positions.second > maxDimension) {
      positions.first -= positions.second - maxDimension;
      if (positions.first < 0) {
        positions.first = 0;
      }
      positions.second = maxDimension;
    }
    return positions;
  }

  refreshDimension() {
    this.dimension = this.cropper.x2 - this.cropper.x1;
  }

  onCropperReady(event: Dimensions) {
    this.refreshDimension();

    this.maxWidth = this.imageCropper.maxSize.width;
    this.maxHeight = this.imageCropper.maxSize.height;
    this.maxDimension = Math.min(
      this.imageCropper.maxSize.width,
      this.imageCropper.maxSize.height,
    );
    /* Image cropper changes the dimensions internally and this is the only way I found so
    far to constraint size of manual cropping (via buttons/slider) to our requested sizes. */
    this.minDimension = this.imageCropper['cropperScaledMinHeight'];
  }
}
