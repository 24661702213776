import { Component, Inject, Injector, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { BreakpointService } from '../../../../../../core/services/breakpoint.service';
import { AbstractDialog } from '../../../../../../libs/component-lib/components/dialog/abstract-dialog';
import { Conversation } from '../../../../models/conversation.model';
import { ChatStore } from '../../../../services/chat.store';
import { BlockUiService } from '../../../../../../core/services/block-ui.service';
import { NotificationService } from '../../../../../../core/services/notification.service';
import { getUserFriendlyError } from '../../../../../../utils/errors';
import { Router } from '@angular/router';
import { Url } from '../../../../../../url';

@Component({
  selector: 'app-chat-archive-dialog',
  templateUrl: './chat-archive-dialog.component.html',
  styleUrls: ['./chat-archive-dialog.component.scss'],
})
export class ChatArchiveDialogComponent extends AbstractDialog {
  largeScreen$: Observable<boolean>;

  constructor(
    injector: Injector,
    @Inject(MAT_DIALOG_DATA)
    readonly data: { conversation: Conversation },
    private readonly dialogRef: MatDialogRef<any>,
    private readonly breakpointService: BreakpointService,
    private readonly blockUiService: BlockUiService,
    private readonly notificationService: NotificationService,
    private readonly chatStore: ChatStore,
    private readonly router: Router,
  ) {
    super(injector);
    this.largeScreen$ = this.breakpointService.largeScreen$;
  }

  archiveConversation(conversation: Conversation) {
    this.chatStore
      .archiveConversation(conversation)
      .pipe(this.untilDestroyed(), this.blockUiService.blockPipe())
      .subscribe({
        next: () => {
          this.notificationService.showNotification({
            text: 'Chat was successfully archived',
            color: 'success',
          });
          this.closeDialog();
          this.router.navigate([Url.CHAT]);
        },
        error: err => {
          this.notificationService.showNotification({
            text: getUserFriendlyError(err),
            color: 'error',
          });
        },
      });
  }
}
