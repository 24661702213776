import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BookingMessageData } from 'src/app/modules/chat/models/booking-message.model';
import { BookingInterval } from 'src/app/shared/models/booking';
import { Property } from 'src/app/shared/models/property';

@Component({
  selector: 'app-chat-message-system',
  templateUrl: './chat-message-system.component.html',
  styleUrls: ['./chat-message-system.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageSystemComponent {
  @Input() textMessagePrefix = '';
  @Input() message: BookingMessageData;
  @Input() icon: string;
  @Input() color: string;
  @Input() typeText: string;
  @Input() isTooltipEnabled: boolean = false;
  @Input() linkText = 'Vacay request';

  @Input() conversationId: string;
  @Input() property: Property;
  @Input() bookingId: string;
  @Input() interval: BookingInterval;

  constructor(private readonly router: Router) {}

  navigate() {
    const baseRoute = ['/chat', this.conversationId];
    if (this.bookingId) {
      this.router.navigate([...baseRoute, 'booking', this.bookingId]);
    } else if (this.property) {
      this.router.navigate([...baseRoute, 'property', this.property.id]);
    }
  }

  get infoTextBefore() {
    if (!this.message.sender.isCurrentUser && this.message.sender?.firstName) {
      return `${this.message.sender.firstName} ${this.typeText}`;
    }
    return '';
  }

  get infoTextAfter() {
    if (this.message.sender.isCurrentUser || !this.message.sender?.firstName) {
      return this.typeText;
    }

    return '';
  }

  get text() {
    return this.textMessagePrefix + this.message.payload.text;
  }
}
