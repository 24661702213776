import { Component } from '@angular/core';
import { BreakpointService } from '../../../services/breakpoint.service';

@Component({
  selector: 'app-forbidden-page',
  templateUrl: './forbidden-page.component.html',
  styleUrls: ['./forbidden-page.component.scss'],
})
export class ForbiddenPageComponent {
  constructor(public readonly bs: BreakpointService) {}
}
