<ng-template #topVacayDescription let-data="data">
  <div class="top-vacay-template">
    <p typography variant="h5" class="d-block">
      {{ data.label }}
    </p>
    <div class="location">
      {{ data.location }}
    </div>
    <div class="price" *ngIf="data.price">
      from <b>${{ data.price }}</b> / night
    </div>
  </div>
</ng-template>

<div class="home-background">
  <app-home-property-search
    [modal]="true"
    #searchMenu
  ></app-home-property-search>
  <!-- STICKY MENU-->
  <div class="d-none d-md-block">
    <div
      class="sticky-menu"
      [class.sticky-menu-visible]="stickyMenu"
      [class.sticky-menu-animation]="stickyMenuAnimation"
    >
      <a href="/" class="logo-link" data-test="vacay-logo">
        <img
          loading="lazy"
          src="assets/img/vacay-125.svg"
          alt="VacayMyWay logo"
          class="logo"
        />
      </a>
      <button
        class="open-menu-search mx-16"
        data-test="find-your-vacay-scrollbar"
        (click)="onFindYourVacay()"
      >
        <span typography variant="h6">Find Your Vacay</span>
        <icon variant="chevron_right"></icon>
      </button>
      <div class="actions" *ngIf="isPlatformBrowser()">
        <app-menu-user-panel
          #userMenuSticky
          variant="small"
        ></app-menu-user-panel>
      </div>
    </div>
  </div>
  <div class="d-md-none">
    <div
      class="sticky-menu-mobile"
      [class.sticky-menu-mobile-visible]="stickyMenu"
      [class.sticky-menu-mobile-animation]="stickyMenuAnimation"
    >
      <button
        button
        variant="inline"
        icon="hamburger"
        iconColor="ink"
        (click)="openMobileMenu()"
        class="hamburger"
      ></button>
      <button class="open-menu-search" (click)="onWhereAreYouGoing()">
        <icon variant="search" color="ink"></icon>
        <span typography variant="body2" color="ink" class="ml-12"
          >Where are you going?</span
        >
        <icon variant="chevron_right" color="ink" class="ml-auto"></icon>
      </button>
    </div>
  </div>
  <!-- END OF STICKY MENU-->
  <ng-template #landingMenu>
    <div class="landing-menu">
      <button
        button
        variant="inline"
        icon="hamburger"
        iconColor="ink"
        (click)="openMobileMenu()"
        class="hamburger"
      ></button>
      <a href="/" class="logo-link" data-test="vacay-logo">
        <img
          loading="lazy"
          src="assets/img/vacay-125.svg"
          alt="VacayMyWay logo"
          class="logo"
        />
      </a>
      <span class="hamburger"><!-- dummy element --></span>
      <div class="actions" *ngIf="isPlatformBrowser()">
        <app-menu-user-panel #userMenu></app-menu-user-panel>
      </div>
    </div>
  </ng-template>

  <div>
    <div class="landing">
      <ng-container [ngTemplateOutlet]="landingMenu"></ng-container>
      <div
        class="landing-container"
        id="mainImg"
        [ngStyle]="{
          backgroundImage:
            'url(' +
            environment.content.url +
            'main-home.jpg?version=' +
            buildNumber +
            '), url(' +
            environment.content.url +
            'main-home-low.jpg?version=' +
            buildNumber +
            ')'
        }"
      >
        <h1>
          <img
            loading="lazy"
            src="assets/img/vacay-white.svg"
            alt="VacayMyWay"
            class="heading-logo"
          />
        </h1>
        <h2>A refreshing new way to Vacay.</h2>
        <p>
          Choose from our wide selection of the finest places in the U.S. and
          around the world.
        </p>
        <div class="landing-search-container">
          <app-home-property-search #search></app-home-property-search>
        </div>
      </div>
    </div>

    <div class="picks">
      <ng-container>
        <div class="picks-info">
          <app-home-section-header
            title="Top Vacays"
            subtitle="Some of the best that we have to offer"
            description="A curated list of some of the most amazing vacays available now on our site!"
          ></app-home-section-header>
        </div>
        <div class="picks-container">
        <div *ngIf="vacayPicks1$ | async as picks">
          <app-home-picks
            *ngIf="picks.length !== 0"
            [picks]="picks"
            [descriptionTemplate]="topVacayDescription"
          ></app-home-picks>
        </div>
        <div *ngIf="vacayPicks2$ | async as picks">
          <app-home-picks
            *ngIf="picks.length !== 0"
            [picks]="picks"
            [descriptionTemplate]="topVacayDescription"
          ></app-home-picks>
        </div>
      </div>
        <a button color="primary" href="/search" class="picks-more-button"
          >Show me more</a
        >
      </ng-container>
    </div>
    <div class="picks" *ngIf="locationPicks$ | async as picks">
      <ng-container *ngIf="picks.length !== 0">
        <div class="picks-info">
          <app-home-section-header
            title="Locations"
            subtitle="Popular destinations"
            description="Take your next Vacay at one of these popular locations."
          ></app-home-section-header>
        </div>
        <app-home-picks [picks]="picks"> </app-home-picks>
      </ng-container>
    </div>
    <div class="picks" *ngIf="propertyPicks$ | async as picks">
      <ng-container *ngIf="picks.length !== 0">
        <div class="picks-info">
          <app-home-section-header
            title="Property Types"
            subtitle="A wide selection of options"
            description="Fancy a beach house? A wood cabin? A house with a pool? We got them all!"
          ></app-home-section-header>
        </div>
        <app-home-picks [picks]="picks"></app-home-picks>
      </ng-container>
    </div>
    <app-home-nouns></app-home-nouns>
    <app-home-get-started></app-home-get-started>
    <app-home-host-benefits></app-home-host-benefits>
    <app-home-inspiration></app-home-inspiration>
    <app-home-partners></app-home-partners>
    <app-footer></app-footer>
    <app-home-offer
      *toggle="'homeOffer'"
      [open]="offerOpen"
      (closeButton)="onOfferClose()"
    ></app-home-offer>
  </div>
</div>
