import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { env as environment } from '../../dynamic-environment';

@Injectable({
  providedIn: 'root',
})
export class SentryService {
  init() {
    this.ifEnabledRun(() => {
      Sentry.init({
        release: 'vacay-ui@5dbe1f88481d476c7766cd54383bc5a52c61c82f',
        environment: environment.name,
        dsn: 'https://734a3ad86d794619b8d9da6f28abb032@o436266.ingest.sentry.io/5397076',
        // TryCatch has to be configured to disable XMLHttpRequest wrapping, as we are going to handle
        // http module exceptions manually in Angular's ErrorHandler and we don't want it to capture the same error twice.
        // Please note that TryCatch configuration requires at least @sentry/browser v5.16.0.
        integrations: [
          new TracingIntegrations.BrowserTracing({
            tracingOrigins: [
              'dev.vacaymyway.com',
              'preprod.vacaymywa.com',
              'vacaymywa.com',
            ],
          }),
          new Sentry.Integrations.TryCatch({
            XMLHttpRequest: false,
          }),
        ],
        tracesSampleRate: 0.05,
        ignoreErrors: ['Non-Error exception captured'],
      });
    });
  }

  /**
   * Process unhandled error.
   */
  handleError(error) {
    const eventId = this.processError(error);
    //FIXME: this.ifEnabledRun(() => {
    //   Sentry.showReportDialog({ eventId });
    // });
  }

  /**
   * Report error message into Sentry manually.
   * @param msg custom message
   * @param err optional error details
   */
  reportMessage(msg: string, err?: Error) {
    this.handleErrorMesssage(msg, err);
  }

  /**
   * Process error and return Sentry Event ID.
   * @param error error to process
   */
  private processError(error): string {
    // Try to unwrap zone.js error.
    // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
    if (error && error.ngOriginalError) {
      error = error.ngOriginalError;
    }

    // We can handle messages and Error objects directly.
    if (typeof error === 'string') {
      return this.handleErrorMesssage(error);
    }
    if (error instanceof Error) {
      return this.handleException(error);
    }

    // If it's http module error, extract as much information from it as we can.
    if (error instanceof HttpErrorResponse) {
      // The `error` property of http exception can be either an `Error` object, which we can use directly...
      if (error.error instanceof Error) {
        return this.handleException(error);
      }

      // ... or an`ErrorEvent`, which can provide us with the message but no stack...
      if (error.error instanceof ErrorEvent) {
        return this.handleErrorMesssage(error.error.message, error);
      }

      // ...or the request body itself, which we can use as a message instead.
      if (typeof error.error === 'string') {
        return this.handleErrorMesssage(
          `Server returned code ${error.status} with body "${error.error}"`,
          error,
        );
      }

      // If we don't have any detailed information, fallback to the request message itself.
      return this.handleErrorMesssage(error.message, error);
    }

    // Skip if there's no error, and let user decide what to do with it.
    return this.handleUnknownMesssage(error);
  }

  private handleUnknownMesssage(err?: any): string {
    this.logErr(err);
    return this.ifEnabledRun(() => {
      return Sentry.captureMessage('Handled unknown error', {
        extra: { __serialized__: err },
        level: Sentry.Severity.Error,
      });
    });
  }

  private handleErrorMesssage(msg: string, err?: any): string {
    this.logErr(msg, err);
    return this.ifEnabledRun(() => {
      return Sentry.captureMessage(msg, {
        extra: { __serialized__: err },
        level: Sentry.Severity.Error,
      });
    });
  }

  private handleException(err?: any): string {
    this.logErr(err);
    return this.ifEnabledRun(() => {
      return Sentry.captureException(err);
    });
  }

  private logErr(...args) {
    // If console error logging is enabled, log the error to console for immediate feedback.
    if (environment.sentry.logError) {
      console.error(...args);
    }
  }

  private ifEnabledRun(fn: () => void): any {
    if (environment.sentry.enabled) {
      return fn();
    }
  }
}
