import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Host,
  Input,
  OnInit,
  Optional,
  Output,
  Renderer2,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import {
  ControlContainer,
  UntypedFormControl,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  NgForm,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { SubmittableFormControl } from '../../../utils/form.util';
import { ParentComponent } from './parent.component';
import { IconVariant } from './icon/icon.component';
import { IConfig } from 'ngx-mask';

export class TextErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    return !!(
      control &&
      control.invalid &&
      ((control.touched && control.dirty) ||
        (control as SubmittableFormControl).submitted)
    );
  }
}

@Component({
  selector: 'input-text',
  template: `
    <mat-form-field
      [class.input-full-width]="fullWidth"
      (click)="onInputClick()"
    >
      <mat-label>
        {{ label }}
        <span *ngIf="required" class="required-asterisk">*</span>
      </mat-label>
      <input
        #input
        matInput
        [formControl]="formControl"
        [autocomplete]="autocomplete ? 'on' : 'off'"
        [type]="'text'"
        [errorStateMatcher]="matcher"
        [mask]="mask"
        [patterns]="maskPatterns"
        [prefix]="maskPrefix"
        [suffix]="maskSuffix"
        [maxlength]="maxLength"
        [attr.data-test]="dataTest"
        [readonly]="readonly"
        [placeholder]="placeHolder"
        [attr.inputmode]="inputMode"
      />
      <mat-error *ngIf="errorMessage$ | async as errorMessage">
        {{ this.errorMessage }}
      </mat-error>
      <div *ngIf="prefix" matPrefix>
        {{ prefix }}
      </div>
      <div *ngIf="suffix || tooltip || icon" matSuffix>
        <a
          *ngIf="suffix"
          class="input-suffix input-suffix-text"
          (click)="onSuffixClick()"
          >{{ suffix }}</a
        >
        <icon
          *ngIf="icon"
          class="input-suffix input-suffix-icon"
          [variant]="icon"
          [attr.data-test]="iconDataTest"
          (click)="onSuffixClick()"
          tooltipPosition="after"
        ></icon>
        <icon
          *ngIf="tooltip"
          class="input-suffix input-suffix-icon"
          variant="info"
          [tp]="iconTooltip"
          tpPlacement="bottom-end"
        ></icon>
        <ng-template #iconTooltip>
          <div *ngIf="tooltip" typography variant="body1" color="white">
            {{ tooltip }}
          </div>
        </ng-template>
      </div>
    </mat-form-field>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextComponent),
      multi: true,
    },
  ],
})
export class InputTextComponent extends ParentComponent implements OnInit {
  @Input() tooltip?: string;

  @Input() suffix?: string;

  @Input() icon?: IconVariant;
  @Input() iconDataTest?: string;
  /** Input data test */
  @Input() dataTest?: string;

  @Input() prefix?: string;

  @Input() readonly = false;

  @Input()
  type = 'text';

  @Input()
  autocomplete = true;

  @Input()
  inputMode: string;

  @Input()
  mask?: string;

  @Input()
  maskPatterns: IConfig['patterns'];

  @Input()
  maskPrefix = '';

  @Input()
  maskSuffix = '';

  @Input()
  maxLength?: number;

  @Input()
  placeHolder?: string;

  @Input()
  required?: boolean;

  @Output()
  clickEvent = new EventEmitter();

  @Output()
  inputClickEvent = new EventEmitter();

  matcher = new TextErrorStateMatcher();

  @ViewChild('input', { static: false })
  input: ElementRef<HTMLInputElement>;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    @Host() @SkipSelf() @Optional() parentControlContainer: ControlContainer,
  ) {
    super(parentControlContainer);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.renderer.addClass(this.elementRef.nativeElement, 'input');
    this.renderer.addClass(this.elementRef.nativeElement, 'input-text');
  }

  onSuffixClick() {
    this.clickEvent.emit();
  }

  onInputClick() {
    this.inputClickEvent.emit();
  }

  focus() {
    this.input.nativeElement.focus();
  }
}
