import { NgModule } from '@angular/core';
import { RadioButtonGroupAccountsComponent } from './components/radio-button-group-accounts.component';
import { CommonModule } from '@angular/common';
import { RadioButtonLegacyModule } from './radio-button-legacy.module';
import { TypographyModule } from '@components/typography/typography.module';

@NgModule({
  declarations: [RadioButtonGroupAccountsComponent],
  imports: [CommonModule, RadioButtonLegacyModule, TypographyModule],
  exports: [RadioButtonGroupAccountsComponent],
})
export class RadioButtonGroupAccountsLegacyModule {}
