import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../../../core/core.module';
import { ComponentApiModule } from '../../../libs/component-lib/component-api.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { AmenityGroupAccordionComponent } from './components/amenity-group-accordion/amenity-group-accordion.component';
import { AmenityGroupComponent } from './components/amenity-group/amenity-group.component';

@NgModule({
  declarations: [AmenityGroupAccordionComponent, AmenityGroupComponent],
  imports: [
    CommonModule,
    CoreModule,
    ComponentApiModule,
    ReactiveFormsModule,
    RouterModule,
    MatTableModule,
  ],
  exports: [AmenityGroupAccordionComponent, AmenityGroupComponent],
})
export class PropertyAmenityModule {}
