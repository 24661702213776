<div card>
    <h3 typography variant="subheading1" color="ink" class="mb-8">{{title}}</h3>
    <div class="d-flex justify-content-between align-items-start">
        <div class="d-flex align-items-center">
            <avatar [src]="recipient.avatarUrl" size="small">{{
                recipient | userInitials
                }}</avatar>
            <div class="ml-8">
                <p class="d-flex align-items-center" typography variant="subheading1">
                    {{ recipient | userFullName }}
                    <icon color="green" variant="badge_verified" class="ml-4"
                        *ngIf="recipient.verification?.status === VerificationStatus.CONFIRMED"
                        [tp]="userIsVerifiedTooltip">
                    </icon>
                </p>
                <ng-template #userIsVerifiedTooltip>User is verified</ng-template>
                <p *ngIf="recipient.created" typography variant="body3" color="ink-light">Member Since
                    {{recipient.created | date:'MMMM y'}}</p>
            </div>
        </div>
        <div *ngIf="recipient.id" class="d-flex align-items-center">
            <a [routerLink]="[Url.USERS_(recipient.id)]" typography variant="link2" class="ml-8" target='_blank'>View
                Profile</a>
        </div>
    </div>
    <div *ngIf="recipient.phone?.phoneNumber || recipient.email?.emailAddress" class="d-flex flex-column mt-8">
        <h3 typography variant="subheading2" color="ink">Contact Details</h3>
        <span typography variant="body2" class="mt-4">{{recipient.phone?.phoneNumber}}</span>
        <a [href]="'mailto:' + recipient.email?.emailAddress" class="mt-4" typography variant="link2">
            {{recipient.email?.emailAddress}}
        </a>
    </div>
    <div *ngIf="recipient.description && !hideBio" class="mt-8">
        <h3 typography variant="subheading2" color="ink">Bio</h3>
        <multi-paragraph-text [contentText]="recipient.description" contentLinesLimit="2"></multi-paragraph-text>
    </div>
    <div class="d-flex align-items-center mt-8" *ngIf="ratingVisible">
        <stars [value]="1" size="normal" style="margin-left: -4px"></stars>
        <a typography variant="link2" class="ml-8">1 review</a>
    </div>
</div>