import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { TypographyModule } from '@components/typography/typography.module';
import { InputNumberComponent } from './components/input-number.component';
import { IconModule } from '@components/icon/icon.module';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { NgxMaskDirective } from 'ngx-mask';
import { TippyDirective } from '@ngneat/helipopper';
import initTooltip from './utils/init-tooltip';

@NgModule({
  declarations: [InputNumberComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    NgxMaskDirective,
    TypographyModule,
    IconModule,
    TippyDirective,
  ],
  providers: [initTooltip()],
  exports: [InputNumberComponent],
})
export class InputNumberLegacyModule {}
