import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DialogService } from '../../../../core/services/dialog.service';
import { SignUpComponent } from '../../../auth/components/signup/dialog/sign-up.component';

@Component({
  selector: 'app-home-offer',
  templateUrl: './home-offer.component.html',
  styleUrls: ['./home-offer.component.scss'],
})
export class HomeOfferComponent {
  @Input()
  open: boolean;
  @Output()
  closeButton = new EventEmitter<void>();

  constructor(private readonly dialogService: DialogService) {}

  onClose() {
    this.closeButton.emit();
  }
}
