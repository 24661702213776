import { Injectable } from '@angular/core';
import {
  User,
  UserPasswordChange,
  UserUnreadConversations,
} from '../../shared/models/user.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { handleGetUserError } from '../util/api.util';
import { API_USER_BASE } from '../../url';

/** Users API endpoint base path. */
const USER_USERS_API_BASE = API_USER_BASE + '/users';

@Injectable({
  providedIn: 'root',
})
export class UserApi {
  constructor(private readonly http: HttpClient) {}

  registerUser(
    email: string,
    password: string,
    firstName: string,
    lastName: string,
  ): Observable<User> {
    const user = { email, password, firstName, lastName };
    return this.http.post<User>(`${USER_USERS_API_BASE}/register`, user);
  }

  login() {
    // This is not very clear
    return this.http.post<User>(`${USER_USERS_API_BASE}/login`, {});
  }

  getUser(id: string): Observable<User> {
    return this.http
      .get<User>(`${USER_USERS_API_BASE}/${id}`)
      .pipe(handleGetUserError());
  }

  createUser(firstName: string, lastName: string) {
    return this.http.post<User>(`${USER_USERS_API_BASE}/register-social`, {
      firstName,
      lastName,
    });
  }

  createSmsUser(
    email: string,
    firstName: string,
    lastName: string,
  ): Observable<any> {
    return this.http.post<any>(`${USER_USERS_API_BASE}/register-sms`, {
      email,
      firstName,
      lastName,
    });
  }

  fetchCurrentUser(): Observable<User> {
    return this.http
      .get<User>(`${USER_USERS_API_BASE}/current`)
      .pipe(handleGetUserError());
  }

  fetchCurrentUserUnreadConversations(): Observable<UserUnreadConversations> {
    return this.http
      .get<UserUnreadConversations>(
        `${USER_USERS_API_BASE}/current/unread-conversations`,
      )
      .pipe(handleGetUserError());
  }

  updateCurrentUser(user: Partial<User>) {
    return this.http.patch<User>(`${USER_USERS_API_BASE}/current`, { ...user });
  }

  updateEmailVerified(email: string) {
    return this.http.post(`${USER_USERS_API_BASE}/verify-email`, { email });
  }

  resetPassword(email: string) {
    return this.http.post(`${USER_USERS_API_BASE}/reset-password`, { email });
  }

  sendVerificationEmail() {
    return this.http.post(`${USER_USERS_API_BASE}/send-verification-email`, {});
  }

  /** Password change request. */
  changePassword(data: UserPasswordChange) {
    return this.http.put(`${USER_USERS_API_BASE}/current/password`, data);
  }

  doesUserExist(email: string): Observable<boolean> {
    return this.http.get<boolean>(
      `${USER_USERS_API_BASE}/user-exists/${email.toLowerCase()}`,
      { responseType: 'json' },
    );
  }
}
