<div *ngIf="recipient && (bookingInfo$ | async) as bookingInfo">
    <div class="d-flex justify-content-between align-items-center mx-16 my-24">
        <app-property-booking-header [booking]="bookingInfo"></app-property-booking-header>
        <a link (click)="closeDetail()">
            <icon size="medium-large" variant="cross"></icon>
        </a>
    </div>

    <!-- Property info START -->
    <div card class="mx-16 my-24">
        <app-property-info [property]="property" [isPropertyOwner]="isPropertyOwner"></app-property-info>
    </div>
    <!-- Property info END -->

    <!-- Booking info START -->
    <div card class="mx-16 my-24">
        <app-booking-info [booking]="bookingInfo"></app-booking-info>
        <ng-container
            *ngIf="isPropertyOwner && bookingInfo.status === BookingStatus.APPROVED && bookingInfo.cancelable">
            <div class="d-flex mt-12">
                <button button class="w-100 ml-8" size="small" data-test="cancel-vacay"
                    (click)="navigateCancel(bookingInfo)">Cancel
                    Vacay</button>
            </div>
        </ng-container>
    </div>
    <!-- Booking info END -->

    <!-- GUEST/HOST info START -->
    <div class="mx-16 my-24">
        <app-chat-recipient [title]="recipientTitle" [hideBio]="true" [recipient]="recipient"></app-chat-recipient>
    </div>
    <!-- GUEST/HOST info END -->

    <!-- HOUSE RULES START -->
    <div card class="mx-16 my-24"
        *ngIf="!isPropertyOwner && (bookingInfo.status === BookingStatus.APPROVED || bookingInfo.status === BookingStatus.AUTHORIZED)">
        <app-property-house-rules [property]="property"></app-property-house-rules>
    </div>
    <!-- HOUSE RULES END -->

    <!-- ARRIVAL INSTRUCTIONS -->
    <div card class="mx-16 my-24"
        *ngIf="!isPropertyOwner && property.instructions && (bookingInfo.status === BookingStatus.APPROVED || bookingInfo.status === BookingStatus.AUTHORIZED)">
        <app-property-arrival-instructions [instructions]="property.instructions"></app-property-arrival-instructions>
    </div>
    <!-- ARRTIVAL INSTRUCTIONS END -->

    <!-- PAYOUT DETAILS START -->
    <div card class="content-box mx-16 my-24">
        <app-booking-payout-details [booking]="bookingInfo" [isPropertyOwner]="isPropertyOwner"
            [refundDisabled]="refundDisabled$ | async"></app-booking-payout-details>
    </div>
    <!-- PAYOUT DETAILS END -->

    <!-- Property avaibility START -->
    <div class="mx-16 my-24"
        *ngIf="isPropertyOwner && (bookingInfo.status === BookingStatus.AUTHORIZED || bookingInfo.status === BookingStatus.APPROVED)"
        card>
        <app-property-availability [booking]="bookingInfo" [property]="property"></app-property-availability>
    </div>
    <!-- Property avaibility END -->

    <!-- BOOKING CANCELLATION POLICY START -->
    <div card class="mx-16 my-24" *ngIf="bookingInfo.status !== BookingStatus.CANCELED && !historyBooking(bookingInfo)">
        <app-booking-cancellation-policy [booking]="bookingInfo"
            [refundRule]="refundRule"></app-booking-cancellation-policy>
    </div>
    <!-- BOOKING CANCELLATION POLICY END -->

    <!-- REFUND TRACKING START -->
    <div *ngIf="!isPropertyOwner && bookingInfo.status === BookingStatus.CANCELED && refundRule" card class="mx-16 my-24">
        <app-booking-refund-tracking [refundRule]="refundRule"></app-booking-refund-tracking>
    </div>
    <!-- REFUND TRACKING END -->
</div>
