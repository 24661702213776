import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BlockUiService } from '../../../../core/services/block-ui.service';
import { UserService } from '../../../../core/services/user.service';
import { markAsSubmitted, updateControls } from '../../../../utils/form.util';
import { AbstractComponent } from '../../../../core/components/abstract/abstract.component';
import { AuthService } from '../../../../core/services/auth.service';
import { DialogService } from '../../../../core/services/dialog.service';
import { GeocodingService } from '../../../../core/services/geocoding.service';
import { NotificationService } from '../../../../core/services/notification.service';
import { SignUpTermsAndConditionsComponent } from '../signup/sign-up-terms-and-conditions/sign-up-terms-and-conditions.component';
import { SignUpPrivacyPolicyComponent } from '../signup/signup-privacy-policy/sign-up-privacy-policy.component';
import { Auth0PhoneVerificationDialogComponent } from '../phone-verification/auth0-phone-verification-dialog.component';
import { StorageKey } from '../../../../shared/enums/local-storage-key.enum';
import { StorageService } from '../../../../core/services/storage.service';

/**
 * Component to handle passwordless login via auth0
 * @author Jakub Novak
 */
@Component({
  selector: 'app-p1h0m89-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneInputComponent extends AbstractComponent implements OnInit {
  form: UntypedFormGroup;

  @Input()
  authType: 'login' | 'signup';

  constructor(
    private readonly notificationService: NotificationService,
    private readonly authService: AuthService,
    private readonly dialogService: DialogService,
    private readonly geoService: GeocodingService,
    private readonly userService: UserService,
    private readonly blockUiService: BlockUiService,
    private readonly storageService: StorageService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      phoneNumber: new UntypedFormControl(undefined, [
        Validators.required,
        Validators.maxLength(15),
      ]),
    });

    if (this.authType === 'signup') {
      this.form.addControl(
        'agreeTermsAndConditions',
        new UntypedFormControl(false, [Validators.requiredTrue]),
      );
      this.form.addControl(
        'agreePrivacyPolicy',
        new UntypedFormControl(false, [Validators.requiredTrue]),
      );
    }
  }

  onSubmit(event: Event) {
    event.preventDefault();
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      markAsSubmitted(this.form);
      updateControls(this.form);
      return;
    }

    if (
      this.form.controls.agreeTermsAndConditions?.value &&
      this.form.controls.agreePrivacyPolicy?.value
    ) {
      this.storageService.setSessionItem(
        StorageKey.SIGN_UP_CONSENT_AGREE,
        'true',
      );
    } else {
      this.storageService.removeSessionItem(StorageKey.SIGN_UP_CONSENT_AGREE);
    }

    const phoneNumber = this.form.controls.phoneNumber.value;
    // check, if user with this number exists in our db
    this.auth(phoneNumber, this.authType === 'signup');
  }

  auth(phoneNumber: string, isSignup: boolean) {
    let dialog: MatDialogRef<any>;
    const block = this.blockUiService.block();
    // send verification code to provided phone number
    this.authService.signInPhoneStart(phoneNumber).subscribe(
      () => {
        dialog = this.dialogService.open(
          Auth0PhoneVerificationDialogComponent,
          {
            data: {
              phoneNumber: phoneNumber,
              isPasswordlessAuth: true,
              title: 'Let’s get you verified',
              isPasswordlessAuthSignup: isSignup,
            },
            autoFocus: false,
          },
        );

        dialog
          .afterOpened()
          .pipe(this.untilDestroyed())
          .subscribe(() => {
            block.unblock();
          });

        dialog
          .afterClosed()
          .pipe(this.untilDestroyed())
          .subscribe(errorMessage => {
            if (errorMessage) {
              this.notificationService.showNotification({
                text: errorMessage,
                color: 'error',
                duration: 5000,
              });
            } else {
              if (isSignup) {
                this.notificationService.showNotification({
                  text: 'Your registration was not completed',
                  color: 'error',
                  duration: 5000,
                });
              }
            }
          });
      },
      error => {
        block.unblock();
        this.notificationService.showNotification({
          text: error.error.message,
          color: 'error',
          duration: 5000,
        });
      },
    );
  }

  openTermsAndConditions() {
    this.dialogService
      .open(SignUpTermsAndConditionsComponent)
      .afterClosed()
      .subscribe(response => {
        if (response) {
          this.form.controls.agreeTermsAndConditions.reset(true);
        }
      });
  }

  openPrivacyPolicy() {
    this.dialogService
      .open(SignUpPrivacyPolicyComponent)
      .afterClosed()
      .subscribe(response => {
        if (response) {
          this.form.controls.agreePrivacyPolicy.reset(true);
        }
      });
  }
}
