import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { IconVariant } from './icon/icon.component';

@Component({
  selector: 'chip',
  template: `
    <div class="chip-container" (click)="onClickEvent($event)" role="button">
      <icon
        *ngIf="icon"
        [variant]="icon"
        color="inherit"
        class="chip-icon"
      ></icon>
      <span>
        {{ label }}
      </span>
      <button
        button
        variant="inline"
        (click)="onCloseEvent($event)"
        *ngIf="showClose"
        class="chip-close-button"
      >
        <icon variant="cross" color="white"></icon>
      </button>
    </div>
  `,
})
export class ChipComponent implements OnInit, OnChanges {
  @Input() icon: IconVariant = undefined;

  @Input() label: string;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output()
  chipClick = new EventEmitter<void>();
  @Output()
  chipClose = new EventEmitter<void>();

  @Input()
  active = false;
  @Input()
  disabled = false;
  @Input()
  showClose = false;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  ngOnInit() {
    this.renderer.addClass(this.elementRef.nativeElement, 'chip');

    if (this.disabled) {
      this.renderer.addClass(this.elementRef.nativeElement, 'disabled');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.active) {
      if (changes.active.currentValue) {
        this.renderer.addClass(this.elementRef.nativeElement, 'chip-active');
      } else {
        this.renderer.removeClass(this.elementRef.nativeElement, 'chip-active');
      }
    }
  }

  onClickEvent(event?: Event) {
    if (this.disabled) {
      return;
    }
    this.chipClick.emit();
  }

  onCloseEvent(event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    if (this.disabled) {
      return;
    }
    this.chipClose.emit();
  }
}
