import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ChatService } from '../../../services/chat.service';
import {
  Conversation,
  ConversationParticipant,
} from '../../../models/conversation.model';
import { AbstractComponent } from '../../../../../core/components/abstract/abstract.component';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { User } from '../../../../../shared/models/user.model';
import { UserService } from '../../../../../core/services/user.service';
import { BreakpointService } from '../../../../../core/services/breakpoint.service';
import { ChatStore } from '../../../services/chat.store';
import { ActivatedRoute, Router } from '@angular/router';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { env } from '../../../../../dynamic-environment';

interface ConversationData extends Conversation {
  id: string;
  recipient: ConversationParticipant;
  hasUnreadMessages: boolean;
  infoText: string;
  infoColor: 'warning' | 'error';
}

enum ConversationFilterType {
  ALL = 'all',
  UNREAD = 'unread',
  ARCHIVED = 'archived',
}

@Component({
  selector: 'app-chat-conversations',
  templateUrl: './chat-conversations.component.html',
  styleUrls: ['./chat-conversations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatConversationsComponent
  extends AbstractComponent
  implements OnInit
{
  private conversationsSubject: BehaviorSubject<ConversationData[]>;
  conversations$: Observable<ConversationData[]>;

  private readonly conversationPageSize = 20;
  lastPage: number = undefined;
  finalPageLoaded = false;
  loadingPage: boolean;

  currentUser: User;
  currentUser$: Observable<User>;
  largeScreen = this.bs.largeScreen$;

  filterOpen = false;
  filter: ConversationFilterType;

  @ViewChild('listRef', { static: true })
  listRef: ElementRef<HTMLDivElement>;

  @Input()
  conversation: Conversation;

  readonly ConversationFilterType = ConversationFilterType;
  readonly filterOptions = Object.values(ConversationFilterType);

  constructor(
    private readonly chatService: ChatService,
    private readonly chatStore: ChatStore,
    private readonly userService: UserService,
    private readonly bs: BreakpointService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    super();
    this.conversationsSubject = new BehaviorSubject<ConversationData[]>(
      undefined,
    );
    this.conversations$ = this.conversationsSubject.asObservable();
  }

  ngOnInit(): void {
    this.currentUser$ = this.userService
      .getCurrentUser()
      .pipe(this.untilDestroyed());
    this.currentUser$.subscribe(user => {
      this.currentUser = user;
    });
    this.chatStore.conversationChanges$
      .pipe(this.untilDestroyed())
      .subscribe(conversation => {
        const participant = conversation.participants.find(
          p => p.userId === this.currentUser.id,
        );
        const archived = participant?.archived;
        const unread = participant.lastReadAt < conversation.lastMessageAt;
        const loaded = this.conversationsSubject
          .getValue()
          ?.find(c => c.id === conversation.id);
        switch (this.filter) {
          case ConversationFilterType.ALL:
            if (archived) {
              this.removeConversation(conversation);
              return;
            }
            break;
          case ConversationFilterType.ARCHIVED:
            if (!archived) {
              this.removeConversation(conversation);
              return;
            }
            break;
          case ConversationFilterType.UNREAD:
            if (loaded) {
              break;
            }
            if (!unread) {
              return;
            }
            break;
        }
        this.addConversations([conversation]);
      });

    // Query filter parameter
    this.activatedRoute.queryParamMap
      .pipe(
        this.untilDestroyed(),
        map(params => params.get('filter')),
        map(value => {
          if (Object.values(ConversationFilterType).includes(value as any)) {
            return value as ConversationFilterType;
          }
          return ConversationFilterType.ALL;
        }),
        distinctUntilChanged(),
      )
      .subscribe(conversationsFilter => {
        this.filter = conversationsFilter;
        // Reset conversation values on filter change
        this.conversationsSubject.next(undefined);
        this.finalPageLoaded = false;
        this.lastPage = undefined;
        this.loadingPage = false;
        this.loadConversations();
      });
  }

  loadConversations() {
    const conversationFilter = this.filter;
    if (this.loadingPage || this.finalPageLoaded) {
      return;
    }
    this.loadingPage = true;
    let loadPage;
    if (this.lastPage === undefined) {
      loadPage = 0;
    } else {
      loadPage = this.lastPage + 1;
    }
    const options = {
      page: loadPage,
      size: this.conversationPageSize,
      archived: undefined,
      unread: undefined,
    };
    switch (this.filter) {
      case ConversationFilterType.ALL:
        options.archived = false;
        break;
      case ConversationFilterType.ARCHIVED:
        options.archived = true;
        break;
      case ConversationFilterType.UNREAD:
        options.unread = true;
        break;
    }
    this.chatStore
      .getConversations(options)
      .pipe(
        this.untilDestroyed(),
        filter(() => this.filter === conversationFilter),
      )
      .subscribe(pagedData => {
        this.lastPage = pagedData.page;
        this.loadingPage = false;
        if (pagedData.last) {
          this.finalPageLoaded = true;
        }
        this.addConversations(pagedData.data);
      });
  }

  addConversations(conversationsToAdd: Conversation[]) {
    let conversations = this.conversationsSubject.getValue() ?? [];
    conversations = conversations.filter(
      c1 => !conversationsToAdd.some(c2 => c1.id === c2.id),
    );
    conversations.push(...(conversationsToAdd as ConversationData[]));
    conversations = this.sortConversations(conversations);
    conversations = conversations.map(c =>
      this.mapConversationToConversationData(c),
    );
    this.conversationsSubject.next(conversations);
  }

  removeConversation(conversation: Conversation) {
    let conversations = this.conversationsSubject.getValue() ?? [];
    conversations = conversations.filter(c => c.id !== conversation.id);
    this.conversationsSubject.next(conversations);
  }

  onListScroll(event: Event) {
    const element = this.listRef.nativeElement;
    const scrollTop = element.scrollTop;
    const height = element.scrollHeight - element.clientHeight;
    if (height - scrollTop < 200) {
      this.loadConversations();
    }
  }

  private mapConversationToConversationData(
    conversation: Conversation,
  ): ConversationData {
    const currentUser = this.currentUser;
    // Find first participant who are not the current user
    let recipientIndex = conversation.participants.findIndex(
      p => p.userId !== currentUser?.id,
    );
    const currentUserParticipant = conversation.participants.find(
      p => p.userId === currentUser?.id,
    );
    // If there is only current user in participants array, take them
    if (recipientIndex < 0) {
      recipientIndex = 0;
    }
    const isInquiry = !conversation.context?.find(c => c.bookingId);
    return {
      ...conversation,
      recipient: conversation.participants[recipientIndex],
      hasUnreadMessages:
        !conversation.open &&
        conversation.lastMessageAt > currentUserParticipant.lastReadAt,
      infoText: isInquiry ? 'Inquiry' : 'Booking',
      infoColor: 'warning',
    } as ConversationData;
  }

  private sortConversations<T extends Conversation>(conversations: T[]): T[] {
    return conversations.sort(
      (c1, c2) => c2.lastMessageAt.getTime() - c1.lastMessageAt.getTime(),
    );
  }

  toggleFilter() {
    this.filterOpen = !this.filterOpen;
  }

  setFilter(type: ConversationFilterType) {
    this.filterOpen = false;
    this.router.navigate([], {
      queryParams: {
        filter: type === ConversationFilterType.ALL ? undefined : type,
      },
      queryParamsHandling: 'merge',
    });
  }
}
