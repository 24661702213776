import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { ICON_REGISTRY } from './icon-registry';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BreakpointService } from '../../../../core/services/breakpoint.service';
import { AbstractComponent } from '../../../../core/components/abstract/abstract.component';

export type IconSize =
  | 'x-small'
  | 'small'
  | 'medium'
  | 'medium-large'
  | 'large';
export type IconVariant = keyof typeof ICON_REGISTRY;
export type IconColor =
  | 'primary'
  | 'secondary'
  | 'inherit'
  | 'warning'
  | 'error'
  | 'gray'
  | 'white'
  | 'yellow'
  | 'tertiary'
  | 'success'
  | 'star'
  | 'black'
  | 'tertiary-bright';

@Component({
  selector: 'icon',
  template: `
    <span
      *ngIf="!tooltipTitle && !tooltip; else iconWithTooltip"
      [innerHTML]="svg"
      class="icon-container icon-color-{{ color }}"
    ></span>
    <ng-template #iconWithTooltip>
      <ng-template #iconTooltipContent (click)="iconTooltip.hide()">
        <div>
          <div
            *ngIf="tooltipTitle"
            typography
            variant="subheading2"
            color="white"
          >
            {{ tooltipTitle }}
          </div>
          <div *ngIf="tooltip" typography variant="body1" color="white">
            {{ tooltip }}
          </div>
        </div>
      </ng-template>
      <span
        [innerHTML]="svg"
        class="icon-container icon-color-{{ color }}"
        #iconTooltip="tippy"
        [tp]="iconTooltipContent"
        [tpPlacement]="_tooltipPosition"
      ></span>
    </ng-template>
  `,
})
export class IconComponent
  extends AbstractComponent
  implements OnInit, OnChanges
{
  @Input()
  variant: IconVariant;
  @Input()
  size: IconSize = 'medium';
  @Input()
  color: IconColor = 'primary';
  @Input()
  tooltipTitle: string;
  @Input()
  tooltip: string;
  @Input()
  tooltipPosition: string = 'after';
  _tooltipPosition: string;

  svg!: SafeHtml;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly sanitizer: DomSanitizer,
    private readonly bs: BreakpointService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.svg = this.sanitizer.bypassSecurityTrustHtml(
      ICON_REGISTRY[this.variant],
    );
    this.renderer.addClass(this.elementRef.nativeElement, 'icon');
    this.renderer.addClass(
      this.elementRef.nativeElement,
      `icon-size-${this.size}`,
    );

    this.bs.largeScreen$.pipe(this.untilDestroyed()).subscribe(largeScreen => {
      if (this.tooltipPosition === 'before') {
        this._tooltipPosition = largeScreen ? 'letf' : 'top';
      } else if (this.tooltipPosition === 'after') {
        this._tooltipPosition = largeScreen ? 'right' : 'bottom';
      } else {
        this._tooltipPosition = this.tooltipPosition;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.variant) {
      this.svg = this.sanitizer.bypassSecurityTrustHtml(
        ICON_REGISTRY[this.variant],
      );
    }
  }
}
