import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TypographyColor, TypographyVariant } from '../typography.component';

@Component({
  selector: 'dialog-header',
  template: `
    <div class="dialog-header">
      <h2
        typography
        [variant]="titleVariant"
        textAlign="left"
        [color]="titleColor"
        *ngIf="title"
      >
        {{ title }}
      </h2>
      <ng-content></ng-content>
      <button
        *ngIf="showClose && dialogClose.observers.length"
        button
        variant="icon"
        title="Close dialog"
        aria-label="close dialog"
        data-test="button-close"
        (click)="closeDialog()"
      >
        <icon variant="cross" size="medium-large"></icon>
      </button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogHeaderComponent {
  @Input() title = '';

  @Input() titleVariant: TypographyVariant = 'h4';

  @Input() titleColor: TypographyColor = 'black';

  @Input() showClose = true;

  @Output() dialogClose = new EventEmitter();

  closeDialog() {
    this.dialogClose.emit();
  }
}
