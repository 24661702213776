import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebGlSupportComponent } from '@components/web-gl-support/web-gl-support.component';
import { TypographyModule } from '@components/typography/typography.module';

@NgModule({
  declarations: [WebGlSupportComponent],
  imports: [CommonModule, TypographyModule],
  exports: [WebGlSupportComponent],
})
export class WebGlSupportModule {}
