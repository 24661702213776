import { Component, Inject, Injector, ViewChild } from '@angular/core';
import { AbstractDialog } from '../../../../../../libs/component-lib/components/dialog/abstract-dialog';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { InputTextAreaComponent } from '../../../../../../libs/component-lib/components/input-textarea.component';
import { ChatTag, ChatTemplate } from './chat-template.model';
import { BreakpointService } from '../../../../../../core/services/breakpoint.service';
import { TemplateService } from '../../../../services/template.service';
import { NotificationService } from '../../../../../../core/services/notification.service';
import { DialogService } from '../../../../../../core/services/dialog.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ChatTemplateDialogComponent } from './chat-template-dialog.component';

@Component({
  selector: 'chat-template-edit-dialog',
  template: `
    <dialog-wrapper-legacy
      width="md"
      [position]="(largeScreen$ | async) ? 'center' : 'bottom'"
    >
      <dialog-header
        [title]="!this.oldLabel ? 'Create new template' : ''"
        (dialogClose)="closeDialog()"
      >
        <button
          button
          type="button"
          variant="link"
          class="d-flex"
          (click)="backButtonClick()"
          *ngIf="this.oldLabel"
        >
          <icon variant="chevron_left" color="secondary"></icon>
          <span typography variant="link1" color="secondary">Back</span>
        </button>
      </dialog-header>
      <dialog-content-legacy>
        <form [formGroup]="formGroup">
          <input-text
            label="Template name"
            [formControl]="formGroup.controls.label"
            [fullWidth]="true"
          ></input-text>
          <input-text
            label="Template subject"
            [formControl]="formGroup.controls.subject"
            [fullWidth]="true"
          ></input-text>
          <p typography variant="subheading1" color="primary">Tags</p>
          <div class="d-flex flex-wrap mt-8 mb-16">
            <chip
              [label]="tag.label"
              class="m-4"
              (chipClick)="addTagToText(tag.tag)"
              *ngFor="let tag of tags"
            ></chip>
          </div>
          <input-textarea
            label="Message body"
            [formControl]="formGroup.controls.textOfMessage"
            [fullWidth]="true"
            [rows]="12"
            [displayLength]="true"
            [maxlength]="1000"
            #textOfMessage
          ></input-textarea>
        </form>
      </dialog-content-legacy>
      <dialog-actions-legacy>
        <button
          button
          type="button"
          variant="contained"
          color="primary"
          [fullWidth]="true"
          (click)="save()"
        >
          {{ !this.oldLabel ? 'Save template' : 'Save changes' }}
        </button>
      </dialog-actions-legacy>
    </dialog-wrapper-legacy>
  `,
})
export class ChatTemplateEditDialogComponent extends AbstractDialog {
  largeScreen$ = this.breakpointService.largeScreen$;
  oldLabel: string;

  formGroup = new UntypedFormGroup({
    label: new UntypedFormControl(undefined, {
      updateOn: 'blur',
      validators: [Validators.required],
    }),
    subject: new UntypedFormControl(undefined, {
      updateOn: 'blur',
      validators: [Validators.required],
    }),
    textOfMessage: new UntypedFormControl(undefined, {
      updateOn: 'blur',
      validators: [Validators.required],
    }),
  });

  @ViewChild('textOfMessage') textOfMessage: InputTextAreaComponent;

  tags: ChatTag[];

  constructor(
    injector: Injector,
    private readonly breakpointService: BreakpointService,
    private readonly templateService: TemplateService,
    private readonly notificationService: NotificationService,
    private readonly dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) data: { template: ChatTemplate },
  ) {
    super(injector);
    if (data.template) {
      this.oldLabel = data.template.label;
      this.formGroup.controls.label.setValue(data.template.label);
      this.formGroup.controls.subject.setValue(data.template.subject);
      this.formGroup.controls.textOfMessage.setValue(
        data.template.textOfMessage,
      );
    }
    this.tags = this.templateService.tags;
  }

  backButtonClick() {
    this.dialogService.clearAndOpen(ChatTemplateDialogComponent);
  }

  save() {
    if (this.formGroup.valid) {
      let didSave: boolean;
      if (this.oldLabel) {
        didSave = this.templateService.saveTemplate(this.oldLabel, {
          label: this.formGroup.controls.label.value,
          subject: this.formGroup.controls.subject.value,
          textOfMessage: this.formGroup.controls.textOfMessage.value,
        });
      } else {
        didSave = this.templateService.saveNewTemplate({
          label: this.formGroup.controls.label.value,
          subject: this.formGroup.controls.subject.value,
          textOfMessage: this.formGroup.controls.textOfMessage.value,
        });
      }
      if (didSave) {
        this.dialogService.clearAndOpen(ChatTemplateDialogComponent);
      }
    } else {
      this.formGroup.markAllAsTouched();
    }
  }

  addTagToText(tag: string) {
    this.textOfMessage.focus();
    const cursorPosition =
      this.textOfMessage.input.nativeElement.selectionStart;

    if (this.formGroup.controls.textOfMessage.value) {
      this.formGroup.controls.textOfMessage.setValue(
        this.formGroup.controls.textOfMessage.value.slice(0, cursorPosition) +
          tag +
          this.formGroup.controls.textOfMessage.value.slice(cursorPosition),
      );
    } else {
      this.formGroup.controls.textOfMessage.setValue(tag);
    }
    this.textOfMessage.input.nativeElement.setSelectionRange(
      cursorPosition + tag.length,
      cursorPosition + tag.length,
    );
  }
}
