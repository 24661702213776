import { Component, Injector } from '@angular/core';
import { ChatTemplate } from './chat-template.model';
import { DialogService } from '../../../../../../core/services/dialog.service';
import { AbstractDialog } from '../../../../../../libs/component-lib/components/dialog/abstract-dialog';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
} from '@components/confirmation-dialog/confirmation-dialog.component';
import { ChatTemplateEditDialogComponent } from './chat-template-edit-dialog.component';
import { TemplateService } from '../../../../services/template.service';
import { BreakpointService } from '../../../../../../core/services/breakpoint.service';

@Component({
  selector: 'chat-template-dialog',
  template: `
    <dialog-wrapper-legacy
      [position]="(largeScreen$ | async) ? 'center' : 'bottom'"
    >
      <dialog-header
        title="Templates"
        (dialogClose)="closeDialog()"
      ></dialog-header>
      <dialog-content-legacy>
        <div class="row my-8" *ngFor="let template of templates">
          <div class="d-flex align-items-center col-10">
            <radio-button
              [label]="template.label"
              [value]="template.textOfMessage"
              (changeValue)="changeValue($event)"
            ></radio-button>
          </div>
          <div class="d-flex align-items-center col-2">
            <button
              button
              type="button"
              variant="icon"
              class="mr-4"
              (click)="editTemplate(template)"
            >
              <icon variant="pen"></icon>
            </button>
            <button
              button
              type="button"
              variant="icon"
              (click)="openRemoveTemplateDialog(template.label)"
            >
              <icon variant="trash" color="white"></icon>
            </button>
          </div>
        </div>
      </dialog-content-legacy>
      <dialog-actions-legacy>
        <div class="row">
          <div class="col-6">
            <button
              button
              variant="contained"
              color="white"
              class="d-flex justify-content-center"
              [fullWidth]="true"
              (click)="createTemplate()"
            >
              <icon variant="plus" color="primary"></icon>
              <span>Create new</span>
            </button>
          </div>
          <div class="col-6">
            <button
              button
              variant="contained"
              color="primary"
              [fullWidth]="true"
              [disabled]="useButtonDisabled"
              (click)="useTemplate()"
            >
              Use template
            </button>
          </div>
        </div>
      </dialog-actions-legacy>
    </dialog-wrapper-legacy>
  `,
})
export class ChatTemplateDialogComponent extends AbstractDialog {
  largeScreen$ = this.breakpointService.largeScreen$;
  useButtonDisabled = true;
  value: string;
  templates: ChatTemplate[];

  constructor(
    injector: Injector,
    private readonly breakpointService: BreakpointService,
    private readonly templateService: TemplateService,
    private readonly dialogService: DialogService,
  ) {
    super(injector);
    this.templates = this.templateService.getTemplates();
  }

  changeValue(value: any) {
    this.useButtonDisabled = false;
    this.value = value;
  }

  openRemoveTemplateDialog(label: string) {
    this.dialogService.clearAndOpen(ConfirmationDialogComponent, {
      data: {
        dialogHeader: 'Are you sure you want to delete this template',
        confirmationText: ' ',
        confirmButton: {
          text: 'Yes, delete',
          onClick: () => {
            this.removeTemplate(label);
            this.dialogService.clearAndOpen(ChatTemplateDialogComponent);
          },
        },
      } as ConfirmationDialogData,
    });
  }

  removeTemplate(label: string) {
    this.templates = this.templateService.removeTemplate(label);
  }

  editTemplate(template: ChatTemplate) {
    this.dialogService.clearAndOpen(ChatTemplateEditDialogComponent, {
      data: {
        template: template,
      },
    });
  }

  createTemplate() {
    this.dialogService.clearAndOpen(ChatTemplateEditDialogComponent, {
      data: {},
    });
  }

  useTemplate() {
    this.templateService.templateSelectedSubject.next(
      this.templateService.replaceTagsInMessage(this.value),
    );
    this.closeDialog();
  }
}
